<template>
    <div id="home">
        <div v-if="playlists_loaded" class="container-fluid">
            <h2>Плейлисты</h2>

            <table class="playlists">
                <tr v-for="pls in playlists" class="playlist" :key="pls.id">
<!--                    <td>#{{ pls.id }}</td>-->
                    <td>
                        <router-link :to="'/playlists/' + pls.id + '/'">{{ pls.title }}</router-link>
                    </td>
                    <td>({{ pls.entries.length }})</td>
                    <td class="lp-actions">
                        <a title="Скачать плейлист" @click="download_playlist(pls.id)"><octicon name="cloud-download"></octicon></a>
                        <a title="Удалить плейлист" @click="deletePlaylist(pls)"><octicon name="trashcan"></octicon></a>
                    </td>
                </tr>
            </table>

            <div class="btn-group">

                <button class="btn btn-small btn-primary" @click="createRecord"><octicon name="diff-added"></octicon> создать плейлист</button>

                <button :disabled="playlists.length < 1" class="btn btn-small btn-success" @click="download_playlists">
                    <octicon name="cloud-download"></octicon> скачать все плейлисты
                </button>

            </div>

        </div>
    </div>

</template>
<script>

    import { tdj2 } from '@/tdj2.js'
    import axios from 'axios'

    export default {

        name: 'playlists',
        props: ['page'],

        data() {
            return {
                state: tdj2.state,
                playlists: [],
                playlists_loaded: false,
                playlist_version: 0

            }
        },

        created() {
            this.fetchPlaylists()
        },

        methods: {
            createRecord() {
                tdj2.startPlaylist(0, this.$root)
                this.fetchPlaylists()
            },
            download_playlist(id) {
                tdj2.download_file('/api/download/playlists/' + id + '/')
            },
            download_playlists() {
                tdj2.download_file('/api/download/playlists/')
            },
            fetchPlaylists() {
                axios.get('/api/playlists/')
                .then((result) => {
                    this.playlists = result.data
                    // this.updatePlayList()
                    this.playlists_loaded = true;
                })
                .catch((error) => {
                    console.log(error)
                })
            },
            deletePlaylist(pls) {
                if (confirm('Удалить плейлист ' + pls.title + '?')) {
                axios.delete('/api/playlists/' + pls.id + '/')
                .then((result) => {
                    this.fetchPlaylists()
                    tdj2.fetchProfile()
                })
                .catch((error) => {
                    console.log(error)
                    alert('Ошибка')
                })
                }
            },

            // updatePlayList() {
            //     this.playlist_version++
            //     this.playlist = []
            //     for (let t of this.tandas) {
            //         for (let te of t.entries) {
            //             this.playlist.push([te.instance.file.play_url, te.instance.file.play_title, te.instance.renders, te.id, te.instance.id])
            //         }
            //     }
            // },
            // checkPlaylist() {
            //     let pls_id = 'tandas_' + this._uid + '_' + this.playlist_version
            //     if (tdj2.state.current_playlist_id !== pls_id) {
            //         tdj2.state.current_playlist_id = pls_id
            //         console.log('switched to playlist ' + pls_id)
            //         tdj2.newPlaylist(this.playlist)
            //     }
            // },
            // playFile(event){
            //     this._playFile(event.target.getAttribute('href'))
            // },
            // _playFile(url) {
            //     this.checkPlaylist()
            //     tdj2.playFile(url, this.$root)
            // },
        }

    }

</script>
<style lang="less">

    table.playlists {
        margin-bottom: 50px;
        tr.playlist {
            background-color: #f5f5f5;

            td {
                margin-bottom: 2px;
                border-top: 1px solid #eeeeee;
                padding: 5px;
                padding-left: 8px;
                padding-right: 8px;
            }

            td.lp-actions {
                a {
                    cursor: pointer;
                }
            }
        }
    }

</style>