<template>
    <RecordingsPage mode="singer" :id="id" :page="page" :parent="currentSinger" v-if="currentSinger.id" />
</template>

<script>

    import {tdj2} from '@/tdj2.js'
    import RecordingsPage from "@/components/RecordingsPage";

    export default {
        components: {RecordingsPage},
        props: ['id', 'page'],
        name: 'Singer',

        created () {

             this.$root.$on('lpSingersLoaded', () => {
                this.processSinger()
            })

            if (this.state.singers_loaded) {
                this.processSinger()
            } else {
                tdj2.fetchSingers(this.$root)
            }

        },

        methods: {

            processSinger: function () {
                for (let o of this.state.singers) {
                    if (String(o.id) == String(this.id)) {
                        this.currentSinger = o
                        console.log('singer processed')
                    }
                }
            },
        },

        data () {
            return {
                state: tdj2.state,
                currentSinger: {name: '', id: null},
            }
        }

    }

</script>

<style lang="less">

</style>
