import { render, staticRenderFns } from "./Tandas.vue?vue&type=template&id=07e36afd"
import script from "./Tandas.vue?vue&type=script&lang=js"
export * from "./Tandas.vue?vue&type=script&lang=js"
import style0 from "./Tandas.vue?vue&type=style&index=0&id=07e36afd&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports