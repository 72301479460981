<template>
  <div id="home">
    <div class="container-fluid main-intro">
    <h2>Добро пожаловать</h2>
      <p>Добро пожаловать на сервер <b>tangodj.online</b>.</p>
      <p>Все вопросы, пожелания и комментарии можно направлять в
        <a href="https://www.facebook.com/groups/163819680991875" target="_blank">соответствующую группу</a> в Facebook.
      </p>
    </div>
    <div class="container-fluid">
      <Statistics></Statistics>
    </div>
  </div>
</template>

<script>
    import { tdj2 } from '@/tdj2.js';
    import Statistics from '@/components/Statistics';
    import axios from 'axios';

    import 'bootstrap/dist/css/bootstrap.css'
    import 'bootstrap-vue/dist/bootstrap-vue.css'
    import "vuetify/dist/vuetify.css";

    export default {
        name: 'home',

        created () {
            console.log('home created')
        },

        data () {
            return {
                state: tdj2.state,
            }
        },

        methods: {

        },

        components: {
          Statistics
        }
    }
</script>

<style lang="less">

  #home {
    background-size: 100% auto;
    background-repeat: repeat-y;
    background-position: left 0%;
  }

  .main-intro {
    margin-bottom: 30px;
  }

</style>
