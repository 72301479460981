<template>

<div>

    <audio ref="audio"
        :src="(state.player_current_track != null && state.player_current_track < state.playlist.length) ? state.playlist[state.player_current_track][0] : ''"
        @ended="onEnded"
        @timeupdate="onTimeUpdate"
        @loadedmetadata="onLoadedMetadata"
        @play="onPlay"
        @pause="onPause"
    >
    </audio>

</div>



</template>
<script>

    import { tdj2} from "@/tdj2";

    export default {

        name: 'PlayerCore',

        data() {
            return {
                timer: null,
                state: tdj2.state
            }
        },

        created() {
            this.$root.$on('player-do-play', this.doPlay);
            this.$root.$on('player-do-pause', this.doPause);
            this.$root.$on('player-do-next', this.doNext);
            this.$root.$on('player-do-prev', this.doPrev);
            this.$root.$on('player-goto', this.goTo);
        },

        methods: {

            resetProgress(){
                this.$root.$emit('player-playing', 0, 0, 0, 0)
            },

            doPlay(isNew=false) {
                if (isNew) {
                    this.resetProgress()
                }
                this.$nextTick(() => {
                    this.$refs.audio.play()
                })
            },

            doPause() {
                this.$nextTick(() => {
                    this.$refs.audio.pause()
                })
            },

            doNext() {
                let nextHandle = () => {
                    this.$root.$emit('player-pause-instance', this.state.playlist[this.state.player_current_track][3])
                    if (this.state.player_current_track + 1 >= this.state.playlist.length) {
                        this.state.player_current_track = 0
                        this.resetProgress()
                    } else {
                        this.state.player_current_track++
                        this.resetProgress()
                        while((this.state.player_current_track + 1 < this.state.playlist.length) && (this.state.playlist[this.state.player_current_track][4] === this.state.playlist[this.state.player_current_track-1][4])) {
                            this.state.player_current_track++
                            this.resetProgress()
                        }
                    }
                    this.$nextTick(() => {
                        this.$refs.audio.play()
                        this.$emit('play-next')
                    })
                }
                nextHandle()
            },

            doPrev() {
                let prevHandle = () => {
                    this.$root.$emit('player-pause-instance', this.state.playlist[this.state.player_current_track][3])
                    if (this.state.player_current_track <= 0) {
                        this.state.player_current_track = this.state.playlist.length - 1
                    } else {
                        this.state.player_current_track--
                    }
                    this.$nextTick(() => {
                        this.$refs.audio.play()
                        this.$emit('play-prev')
                    })
                }
                prevHandle()
            },

            goTo(progress) {
                this.$refs.audio.currentTime = Math.round(progress * this.$refs.audio.duration)
            },

            onPlay() {
                let playHandle = () => {
                    this.$nextTick(() => {
                        this.playing();
                        this.timer = setInterval(this.playing, this.progressInterval)
                        this.state.is_playing = true
                        this.$root.$emit('player-play-instance', this.state.playlist[this.state.player_current_track][3])
                    })
                };
                playHandle();
            },

            onPause() {
                this.$nextTick(() => {
                    clearInterval(this.timer)
                    this.state.is_playing = false
                    this.$root.$emit('player-pause-instance', this.state.playlist[this.state.player_current_track][3])
                })
            },

            onEnded(event) {
                this.onPause()
                this.$emit('ended', event);
                this.doNext();
            },

            onTimeUpdate() {

            },

            onLoadedMetadata() {
                this.$root.$emit('player-new-duration', this.$refs.audio.duration)
            },

            playing() {
                let preload = 0;
                this.$root.$emit(
                    'player-playing',
                    this.$refs.audio.currentTime / this.$refs.audio.duration, // progress
                    this.$refs.audio.currentTime,
                    preload,
                    this.$refs.audio.duration
                )
            },
        },

        beforeDestroy() {
            this.$refs.audio.pause();
            this.onPause();
        },

    }

</script>
<style>

</style>