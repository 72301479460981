<template>
    <div class="login_container">
        <div class="login">
            <h1>tangodj.online</h1>
            <template v-if="!needToken">
                <form method="get" v-on:submit="login">
                    <input type="text" name="u" placeholder="Имя пользователя" v-model.trim="credentials.username" required="required" />
                    <input type="password" name="p" placeholder="Пароль" v-model="credentials.password" @keyup.enter.native="login" required="required" />
                    <button type="submit" class="btn btn-primary btn-block btn-large">Войти</button>
                </form>
            </template>
            <template v-if="needToken">
                <form method="get" v-on:submit="try_token">
                    <p>Для работы с сайтом требуется токен.</p>
                    <input type="text" name="u" placeholder="Token" v-model.trim="token" required="required" />
                    <button type="submit" class="btn btn-primary btn-block btn-large">Применить</button>
                </form>
                <div class="row or"><div class="hr"><hr/></div><div class="or"> или </div><div class="hr"><hr/></div></div>
                <div style="clear:both;"></div>
                <button @click="logout" class="btn btn-primary btn-block btn-large">Выйти</button>

            </template>
        </div>
    </div>
</template>
<script>

    import axios from 'axios'

    import { tdj2 } from '@/tdj2.js'

    export default {
        name: 'Login',
        props: {
            needToken: {
                default: false,
                type: Boolean
            }
        },

        data() {
            return {
                state: tdj2.state,
                credentials: {
                    username: '',
                    password: ''
                },
                token: ''
            }
        },

        methods: {
            login (e) {
              e.preventDefault()

              axios.post('/api/auth/login/', this.credentials)
                .then(result => {
                  // tdj2.setLoggedIn(true)
                  tdj2.fetchProfile(this.$root)
                })
                .catch(error => {
                  if (error.response && error.response.status === 400) {
                    if (error.response.data.non_field_errors[0] === 'Учетная запись пользователя отключена.') {
                      alert('Ваша регистрация еще не подтверждена. Пожалуйста, дождитесь письма с подтверждением или свяжитесь с нами через форму обратной связи.')
                    } else {
                      alert('Неправильный логин или пароль!')
                    }
                  } else {
                    if ('detail' in error.response.data) {
                      alert(error.response.data.detail)
                    }
                    console.log(error)
                  }
                })
            },

            logout() {
                tdj2.logout()
            },

            try_token (e) {
              e.preventDefault()
              axios.post('/be/lptoken/add/', {'token': this.token})
                .then(result => {
                    console.log(result)
                    if (result.data == 'ERROR') {
                        alert('Неправильный токен')
                    }

                    if (result.data == 'OK') {
                        tdj2.state.need_token = false
                        tdj2.setLoggedIn(true)
                        tdj2.fetchProfile(this.$root)
                    }
                })
                .catch(error => {
                    alert('Ошибка!')
                    console.log(error)

                })
            }

        }
    }

</script>
<style lang="less" scoped>

    .login {
        p {
            color: #cccccc;
            font-size: 11pt;
            padding: 7px;
            border: 1px dotted #cccccc;
            text-align: center;
        }
        h1 {
            margin-bottom: 20px;
            sup {
                color: orange;
            }
        }
        .row.or {
            margin-top: 15px;
            height: 40px;
            div {
                float:left;
            }
            .or {
                width: 60px;
                text-align: center;
                font-size: 11pt;
                font-weight: normal;
                color: #aaaaaa;
            }
            .hr {
                width: 120px;
                text-align: center;
                padding-top: 3px;
                hr {
                    border: none; border-top: 1px solid #aaaaaa;
                }
            }
        }
    }

    .btn-primary.btn-facebook {
        background-color: #4267b2;
        background-image: -moz-linear-gradient(top, #4870c1, #4267b2);
        background-image: -ms-linear-gradient(top, #4870c1, #4267b2);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4870c1), to(#4267b2));
        background-image: -webkit-linear-gradient(top, #4870c1, #4267b2);
        background-image: -o-linear-gradient(top, #4870c1, #4267b2);
        background-image: linear-gradient(to bottom, #4870c1, #4267b2);
        background-repeat: repeat-x;
        filter: progid:dximagetransform.microsoft.gradient(startColorstr=#6eb6de, endColorstr=#4267b2, GradientType=0);

        svg {
            display: inline-block;
            margin-bottom: -5px;
            margin-top: -12px;
            width: 20px;
            height: 20px;
            margin-right: 5px;
            fill: white;
        }
    }
        .btn-primary.btn-facebook:hover {
            background-position: 0 -38px !important;
        }


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf) format('truetype');
}
.btn {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 4px 10px 4px;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 18px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -ms-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr=#ffffff, endColorstr=#e6e6e6, GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #e6e6e6;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border: 1px solid #e6e6e6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  *margin-left: 0.3em;
}
.btn:hover,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  background-color: #e6e6e6;
}
.btn-large {
  padding: 9px 14px;
  font-size: 15px;
  line-height: normal;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.btn:hover {
  color: #333333;
  text-decoration: none;
  background-color: #e6e6e6;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -ms-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.btn-primary,
.btn-primary:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  color: #ffffff;
}
.btn-primary.active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-primary {
  background-color: #4a77d4;
  background-image: -moz-linear-gradient(top, #6eb6de, #4a77d4);
  background-image: -ms-linear-gradient(top, #6eb6de, #4a77d4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#6eb6de), to(#4a77d4));
  background-image: -webkit-linear-gradient(top, #6eb6de, #4a77d4);
  background-image: -o-linear-gradient(top, #6eb6de, #4a77d4);
  background-image: linear-gradient(to bottom, #6eb6de, #4a77d4);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr=#6eb6de, endColorstr=#4a77d4, GradientType=0);
  border: 1px solid #3762bc;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.5);
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  filter: none;
  background-color: #4a77d4;
}
.btn-block {
  width: 100%;
  display: block;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.login_container {
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  background: #092756;
  background: -moz-radial-gradient(0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -moz-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), -moz-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -webkit-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), -webkit-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: -o-radial-gradient(0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -o-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), -o-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: -ms-radial-gradient(0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -ms-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), -ms-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), linear-gradient(to bottom, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), linear-gradient(135deg, #670d10 0%, #092756 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3E1D6D", endColorstr="#092756", GradientType=1);
}
.login {
  position: absolute;
  top: 40%;
  left: 50%;
  margin: -150px 0 0 -150px;
  width: 300px;
  height: 340px;
}
.login h1 {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
  text-align: center;
}
input {
  width: 100%;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;
  padding: 10px;
  font-size: 13px;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.2), 0 1px 1px rgba(255, 255, 255, 0.2);
  -webkit-transition: box-shadow 0.5s ease;
  -moz-transition: box-shadow 0.5s ease;
  -o-transition: box-shadow 0.5s ease;
  -ms-transition: box-shadow 0.5s ease;
  transition: box-shadow 0.5s ease;
}
input:focus {
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.4), 0 1px 1px rgba(255, 255, 255, 0.2);
}
</style>