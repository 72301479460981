<template>
    <div id="app" >

        <iframe id="download_host" style="display:none;"></iframe>

        <notifications animation-type="velocity" position="bottom right" :duration="5000" group="foo" />

        <section class="layout" v-if="state.is_user">
            <Navigation></Navigation>
            <div id="content">
                <router-view :key="$route.fullPath"/>
            </div>
            <Footer></Footer>
        </section>

        <template v-if="state.is_guest">
            <Login></Login>
        </template>

        <template v-if="state.need_token">
            <Login :need-token="true"></Login>
        </template>

    </div>
</template>

<script>

    import { tdj2 } from '@/tdj2.js'

    import Navigation from '@/components/Navigation'
    import Footer from '@/components/Footer'
    import Player from '@/components/Player'
    import Login from '@/components/Login'

    export default {
        components: { Navigation, Footer, Player, Login },
        data() {
            return {
                state: tdj2.state
            }
        },
        created() {

        },

        mounted() {

        }
    }
</script>
<style lang="less">

    html {
        width: 100%;
        height: 100%;
        /*overflow: hidden;*/
    }

    body {
        width: 100%;
        height: 100%;
        padding: 0px;
        margin: 0px;
    }

    body.modal-open {
        // to prevent strange issue with freezing safari when popping select
        font-family: "Trebuchet MS" !important;
    }

    div.modal-content {
        margin-bottom: 130px;
    }

    #app {
        width: 100%;
        height: 100%;

        h2 {
            margin-bottom: 30px !important;
        }

        .layout {
            font-family: "Trebuchet MS";
            min-height: 100vh;
            /*overflow: hidden;*/
            display: block;
            position: relative;
            padding-bottom: 240px;

            a {
               color: #5c6bc0;
            }

            #content {
                margin-top: 30px
            }

        }
    }

    .lp-inline-form {
        .form-control {
            display: inline-block !important;
        }
        .form-text {
            display: inline-block !important;
        }
        .row {
            margin-bottom: 0px;
        }
        .lp-checkbox {
            width: 15px;
            height: 32px;
            display: table-cell;
            margin-top: 0px;
            margin-bottom: -10px;
        }
    }

    ul.lp-large {
        font-size: 13pt;
        margin-left: 20px;
        a {
            font-weight: bold;
        }
    }

    #recording_listing {
        padding-left: 3%;
        padding-right: 3%;
    }

    .container-fluid {
        padding-left: 3% !important;
        padding-right: 3% !important;
    }

    nav.navbar {
        padding-left: 0px;
        padding-right: 0px;
    }

    // common

    .container-fluid h2 {
        margin-bottom: 30px !important;
        .octicon {
            margin-top: 10px;
        }
    }

    .lp-tips {
        padding: 15px 40px;
        margin-bottom: 20px;
        border: 1px solid #e8ebff;
        background-color: #f1f3ff;
        font-size: 10pt;
        .octicon {
            margin-top: 0px;
            color: #7885cd;
            position: absolute;
            margin-left: -25px;
        }
        ul {
            margin: 0px;
            padding: 0px;
            margin-left: 25px;
        }

    }

    .lp-alert {
        padding: 10px;
        margin-bottom: 20px;
        border: 1px solid #eecccc;
        background-color: #ffdddd;
        .octicon {
            color: red;
        }
    }

    .lp-info {
        padding: 10px;
        margin-bottom: 20px;
        border: 1px solid #eeeeee;
        background-color: #f5f5f5;
        color: #888888;
        .octicon {
            margin-right: 4px;
        }
    }


    a.red {
        color: red !important;;
    }
    a.red:hover {
        color: red !important;
    }

    .clickable {
        cursor: pointer;
    }

    .lp-float-right {
        float: right;
    }

    .octicon {
        display: inline-block;
        vertical-align: text-top;
        fill: currentColor;
    }

    .octicon {
        margin-top: 2px;
    }


    // tracklist-related

    .badge {
        margin-left: 5px;
        display: inline-block;
        margin-top: 1px;
        padding-top: 3px;
        vertical-align: top;
        font-size: 8pt;
    }

    .badge.badge-lp-entropy {
        background-color: #e2e1f4;
        /*background-color: white;*/
    }

    .badge.badge-lp-modern {
        background-color: rgb(255, 220, 208);
        color: black;
    }

    .badge.badge-lp-tangotunes {
        background-color: rgb(255, 207, 70);
        color: black;
    }

    // tanda list

    .tandas {

        .tanda td {
            font-size: 10pt;
            background-color: #ffd6d6;
            border-top: 1px solid #d6b4b4;

            a {
                color: black !important;
            }

            &.tags {
                padding-top: 0px;
                padding-bottom: 0px;
            }

            .tandatag {
                font-size: 9.5pt;
                padding: 1px 6px;
                margin-right: 7px;
                .octicon {
                    width: 13px;
                    height: 13px;
                    margin-top: 2px;
                }
            }

          }

         .tanda.completed td {
            background-color: #f3f3f3;
            border-top: 1px solid #cccccc;
         }

         .tanda.approved td {
            background-color: #c4ffd0;
             border-top: 1px solid #a4d6af;
        }

         .tanda.colored td {
            background-color: transparent !important;
             border-top: 0px !important;
        }

    }



    .tandatag {
        border: 1px solid;
        font-size: 10pt;
        padding: 2px 6px;
        display: inline-block;
        border-radius: 5px 0px 0px 0px;
        margin-right: 5px;
        .octicon {
                margin-top: 1px;
        }
    }

    .tandatag.tandatag-completed {
        background-color: #eeeeee;
        border-color: #d5d5d5;
    }

    .tandatag.tandatag-approved {
        background-color: #62f691;
        border-color: #44c06a;
    }

    .tandatag.tandatag-radio {
        background-color: #ffd300;
        border-color: #b89600;
        color: #695300;
    }

    .tandatag.tandatag-custom {
        background-color: #eeeeee;
        border-color: #d5d5d5;
    }

    .tandatag.off {
        opacity: 0.4;
    }

</style>
