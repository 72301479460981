
import Vue from 'vue'
import axios from 'axios'

export const tdj2 = {

    state: {

        orchestras: [],
        orchestras_classic: [],
        orchestras_modern: [],
        orchestras_loaded: false,

        genres: [],
        genres_loaded: false,

        singers: [],
        singers_loaded: false,

        workspaces: [],
        workspaces_loaded: false,

        playlist: [['', '', {}],],
        playlist_pending: null,
        current_playlist_id: null,
        player_current_track: null,
        is_playing: false,

        profile_loaded: false,
        is_user: false,
        is_guest: false,
        need_token: false,
        profile: {
            favorite_instances: [],
            favorite_orchestras: [],
        },

    },

    // consts

    pls_cortina: ['/default_cortina.mp3', '~ Cortina ~', {'indigo-inverted': '/render.default_cortina.indigo-inverted.png'}, 0, 0],

    // routines

    setParam(param, value) {
        Vue.cookie.set('tdj2'+param, value, { expires: '1Y' });
        console.log('set param ' + param + ' = ' + value)
    },

    getParam(param, defvalue) {
        let val = Vue.cookie.get('tdj2'+param);
        console.log('got param ' + param + ' = ' + val)
        return val == null ? defvalue : val;
    },

    formatTime(second) {
        return [parseInt((second / 60) % 60), (':' + parseInt(second % 60)).replace(/\b(\d)\b/g, '0$1')].join('')
    },

    idInObjArray(id, arr) {
        for (let el of arr) {
            if (el.id === id) {
                return true;
            }

        }
        return false
    },

    idsFromArray(arr) {
        let ids = [];
        for (let el of arr) {
            ids.push(el.id)
        }
        return ids;
    },

    download_file(url) {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    },

    parse_int(x) {
        var parsed = parseInt(x, 10);
        if (isNaN(parsed)) {
            return 0
        }
        return parsed;
    },

    notify_success(msg, buttons=[]) {
        Vue.notify({
            group: 'foo',
            title: 'Успех',
            text: msg,
            type: 'success',
            buttons: buttons
        })
    },

    notify_warn(msg, buttons=[]) {
        Vue.notify({
            group: 'foo',
            title: 'Внимание',
            text: msg,
            type: 'warn',
            buttons: buttons
        })
    },

    notify_error(msg, error=null, buttons=[]) {
        if (error && error.response && error.response.data) {
            console.log(error.response.data)
            if (error.response.data.detail) {
                msg += ': ' + error.response.data.detail
            }


        }
        Vue.notify({
            group: 'foo',
            title: 'Ошибка',
            text: msg,
            type: 'error',
            buttons: buttons
        })
    },

    notifySuccessWithLink: function (title, msg, button_text, root, button_url) {
        Vue.notify(
            {
                group: 'foo',
                title: title,
                text: msg,
                buttons: [
                    {
                        'title': button_text, 'callback': function () {
                            root.$router.push(button_url)
                        }
                    }],
                type: 'success'
            }
        )
    },

    // user-related methods

    toggleFavoriteInstance(id, root) {
        if (this.state.profile.favorite_instances.includes(id)) {
            this.state.profile.favorite_instances.splice(this.state.profile.favorite_instances.indexOf(id), 1);
        } else {
            this.state.profile.favorite_instances.push(id)
        }
        this.saveProfile(root, true)
    },

    logout() {
        axios.post('/api/auth/logout/')
        .then((result) => {
            tdj2.setLoggedIn(false)
            tdj2.state.need_token = false
        }).catch(error => {
            console.log(error)
        })
    },

    fetchProfile (root=null) {
        console.log('fetch profile')
        axios.get('/api/auth/user/')
        .then((result) => {
            this.state.profile = result.data
            if (this.state.profile.has_token) {
                this.setLoggedIn(true, root)
            } else {
                this.state.is_user = false;
                this.state.is_guest = false;
                this.state.need_token = true;
            }
            this.state.profile_loaded = true
            if (root !== null) {
                root.$emit('lpProfileLoaded')
            }
        }).catch(error => {
            if (error.response.status === 403) {
                this.setLoggedIn(false);
                console.log('403')
            }
            if (error.response.status !== 403) {
                this.notify_error('Ошибка авторизации', error)
                console.log(error)
            }
        })
    },

    saveProfile (root, mute=false, reload=false) {
      axios.put('/api/auth/user/', this.state.profile)
        .then(result => {
          if (result.data.error) {
            this.notify_error(result.data.error)
          } else {
              if (!mute) {
                  this.notify_success('Профиль сохранен')
              }
              root.$emit('lpProfileSaved')
              if (reload) {
                  this.fetchProfile()
              }
          }
        })
        .catch(error => {
            this.notify_error('Ошибка сохранения профиля', error)
            console.log(error)
        })
    },

    setLoggedIn (newValue, root=null) {
        console.log('set logged in: ' + newValue)
        if (newValue) {
            this.state.is_user = true;
            this.state.is_guest = false;
            this.loadUserData(root);
        } else {
            this.state.is_user = false;
            this.state.is_guest = true;
        }
    },

    loadUserData(root=null) {
        this.fetchGenres(root);
        this.fetchSingers(root);
        this.fetchOrchestras(root);
        this.fetchWorkspaces(root);
    },

    getMaxTandaLength(genre) {
        if (!genre) {
            return 4;
        }
        if (['Milonga', 'Candombe'].includes(genre.name)) {
            return 3;
        } else {
            return 4;
        }
    },

    fetchOrchestras: function(root=null) {
        if (this.state.orchestras.length === 0) {
            axios.get('/api/orchestras/')
            .then((result) => {
                Vue.set(this.state, 'orchestras', result.data)
                this.state.orchestras_classic = []
                this.state.orchestras_modern = []
                for (let item of result.data) {
                    if (item.modern) {
                        this.state.orchestras_modern.push(item)
                    } else {
                        this.state.orchestras_classic.push(item)
                    }
                }
                this.state.orchestras_loaded = true
                // console.log('orchestras loaded')
                // console.log('root = ' + root)
                if (root !== null) {
                    root.$emit('lpOrchestrasLoaded')
            }
            })
            .catch((error) => {
                console.log(error)
            })
        }
    },

    fetchSingers: function(root=null) {
        if (this.state.singers.length === 0) {
            axios.get('/api/singers/')
            .then((result) => {
                Vue.set(this.state, 'singers', result.data)
                this.state.singers_loaded = true
                if (root !== null) {
                    root.$emit('lpSingersLoaded')
            }
            })
            .catch((error) => {
                console.log(error)
            })
        }
    },    
    
    fetchGenres: function(root=null) {
        if (this.state.genres.length === 0) {
            axios.get('/api/genres/')
            .then((result) => {
                Vue.set(this.state, 'genres', result.data)
                this.state.genres_loaded = true
                if (root !== null) {
                    root.$emit('lpGenresLoaded')
            }
            })
            .catch((error) => {
                console.log(error)
            })
        }
    },

    fetchWorkspaces: function(root=null) {
        axios.get('/api/workspaces/')
        .then((result) => {
            this.state.workspaces = result.data
            this.state.workspaces_loaded = true
            if (root !== null) {
                root.$emit('lpWorkspacesLoaded')
        }
        })
        .catch((error) => {
            console.log(error)
        })
    },

    // listings-related

    computed_orchestras (listing, skip_ids=[]) {
        return listing.reduce((carry, current) => {
            if (skip_ids.includes(current.id)) {
                return carry
            }
            name = current.orchestra ? current.orchestra.name : 'MIXED'
            if (carry.hasOwnProperty(name) && Array.isArray(carry[name])) {
                carry[name].push(current)
            } else {
            Object.assign(carry, { [name]: [current] })
            }
            return carry
        }, {})
    },

    computed_genres (listing, skip_ids=[]) {
        return listing.reduce((carry, current) => {
            if (skip_ids.includes(current.id)) {
                return carry
            }
            name = current.genre ? current.genre.name : 'MIXED'
            if (carry.hasOwnProperty(name) && Array.isArray(carry[name])) {
                carry[name].push(current)
            } else {
            Object.assign(carry, { [name]: [current] })
            }
            return carry
        }, {})
    },

    computed_years (listing) {
        return listing.reduce((carry, current) => {
            if (carry.hasOwnProperty(current.year) && Array.isArray(carry[current.year])) {
                carry[current.year].push(current)
            } else {
                Object.assign(carry, { [current.year]: [current] })
            }
            return carry
        }, {})
    },

    // content-related

    startTanda(instance_id, root) {
        axios.post('/api/tandas/start/?i=' + instance_id)
        .then((result) => {
            // update context
            tdj2.fetchProfile()
            this.notifySuccessWithLink('Новая танда', 'Танда успешно создана',
                'Перейти к редактированию', root,'/tandas/'+result.data.id+'/')

        })
        .catch((error) => {
            this.notify_error('Ошибка сохранения танды', error)
            console.log(error)
        })
    },

    askForAddingDuplicateTandaEntry: function (tanda, instance_id, root) {
        Vue.notify(
            {
                group: 'foo',
                title: 'Добавление композиции',
                text: 'Эта композиция уже присутствует в танде, вы уверены что хотите добавить её еще раз?',
                buttons: [
                    {
                        'title': 'Да',
                        'callback': function () {
                            tdj2.addInstanceAndSaveTanda(tanda, instance_id, root)
                        }
                    },
                    {
                        'title': 'Нет',
                        'callback': function () {
                        }
                    }
                ],
                type: 'warn',
                duration: -1,
                closeOnClick: false
            }
        );
    },

    addInstanceToTanda(tanda_id, instance_id, recording_id, root) {
        axios.get('/api/tandas/' + tanda_id + '/')
            .then((result) => {
                let tanda = result.data;
                if (tanda.completed) {
                    self.notify_error('Танда помечена как законченная!');
                    return;
                }
                if (this._findInstanceInEntries(tanda.entries, instance_id, recording_id)) {
                    this.askForAddingDuplicateTandaEntry(tanda, instance_id, root);
                    return;
                }
                this.addInstanceAndSaveTanda(tanda, instance_id, root)

            })
            .catch((error) => {
                this.notify_error('Ошибка загрузки танды', error)
                console.log(error)
            })
    },

    askForMarkTandaAsCompletedAndSave: function (tanda, root) {
        Vue.notify(
            {
                group: 'foo',
                title: 'Добавление композиции',
                text: 'В танде достаточно композиций, пометить танду как законченную?',
                buttons: [
                    {
                        'title': 'Да',
                        'callback': function () {
                            tanda.completed = true;
                            tdj2._saveTanda(tanda, root, tanda.id)
                        }
                    },
                    {
                        'title': 'Нет',
                        'callback': function () {
                            tdj2._saveTanda(tanda, root, tanda.id)
                        }
                    }
                ],
                type: 'warn',
                duration: -1,
                closeOnClick: false
            }
        );
    },

    addInstanceAndSaveTanda(tanda, instance_id, root) {

        let max_tanda_length = this.getMaxTandaLength(tanda.genre)

        tanda.entries.push({
            id: null,
            instance: {
                id: instance_id
            },
            order: tanda.entries.length+1
        })

        if (tanda.entries.length === max_tanda_length) {
            this.askForMarkTandaAsCompletedAndSave(tanda, root);
            return;
        }

        this._saveTanda(tanda, root, tanda.id)
    },

    _saveTanda(tanda, root, id=0) {
        let url = id ? '/api/tandas/' + id + '/' : '/api/tandas/'
        let method = id ? 'PUT' : 'POST'
        axios({method: method, url: url, data: tanda})
        .then((result) => {
            // update context
            tdj2.fetchProfile()
            this.notifySuccessWithLink('Танда', 'Танда успешно сохранена',
                'Перейти к редактированию', root, '/tandas/'+result.data.id+'/');
        })
        .catch((error) => {
            this.notify_error('Ошибка сохранения танды', error)
            console.log(error)
        })
    },

    wsTitleTaken(title) {
        for (let p of this.state.workspaces) {
            if (p.title === title) {
                return true;
            }
        }
        return false;
    },

    newWSTitle: function () {
        let title = 'Новое рабочее пространство'
        if (this.wsTitleTaken(title)) {
            let i = this.state.workspaces.length + 1
            while (this.wsTitleTaken(title + ' ' + i)) {
                i++
            }
            title += ' ' + i
        }
        return title;
    },

    startWorkspace(title, root) {
        if (!title) {
            title = this.newWSTitle()
        }
        let ws = {
            id: null,
            name: title
        }

        this._saveWorkspace(ws, root)
    },

    _saveWorkspace(ws, root, id=0) {
        let url = id ? '/api/workspaces/' + id + '/' : '/api/workspaces/'
        let method = id ? 'PUT' : 'POST'
        axios({method: method, url: url, data: ws})
        .then((result) => {
            // update context
            tdj2.fetchProfile(root)
            tdj2.fetchWorkspaces(root)
            this.notify_success( 'Рабочее пространство успешно сохранено');

        })
        .catch((error) => {
            this.notify_error('Ошибка сохранения рабочего пространства', error)
            console.log(error)
        })
    },

    playlistTitleTaken(title) {
        for (let p of this.state.profile.context.playlists) {
            if (p.title === title) {
                return true;
            }
        }
        return false;
    },

    newPlaylistTitle: function () {
        let title = 'Новый плейлист'
        if (this.playlistTitleTaken(title)) {
            let i = this.state.profile.context.playlists.length + 1
            while (this.playlistTitleTaken(title + ' ' + i)) {
                i++
            }
            title += ' ' + i
        }
        return title;
    },

    createPlaylist(root) {
        let playlist = {
            'title': this.newPlaylistTitle(),
            'entries': [
            ]
        };
        return playlist
    },

    startPlaylist(instance_id, root) {
        let playlist = this.createPlaylist()
        
        if (instance_id) {
            playlist.entries.push(
                {
                    id: null,
                    instance: { id: instance_id},
                    order: 1
                }
            )
        } 
        this._savePlaylist(playlist, root)
    },

    addInstanceToPlaylist(playlist_id, instance_id, recording_id, root) {
        axios.get('/api/playlists/' + playlist_id + '/')
            .then((result) => {

                let playlist = result.data;

                if (this._findInstanceInEntries(playlist.entries, instance_id, recording_id)) {
                    this.askForAddingDuplicatePlaylistEntry(playlist, instance_id, root)
                    return;
                }

                this.addInstanceAndSavePlaylist(playlist, instance_id, root)

            })
            .catch((error) => {
                this.notify_error('Ошибка загрузки плейлиста', error)
                console.log(error)
            })
    },

    askForAddingDuplicatePlaylistEntry: function (playlist, instance_id, root) {
        Vue.notify(
            {
                group: 'foo',
                title: 'Добавление композиции',
                text: 'Эта композиция уже присутствует в плейлисте, вы уверены что хотите добавить её еще раз?',
                buttons: [
                    {
                        'title': 'Да',
                        'callback': function () {
                            tdj2.addInstanceAndSavePlaylist(playlist, instance_id, root)
                        }
                    },
                    {
                        'title': 'Нет',
                        'callback': function () {
                        }
                    }
                ],
                type: 'warn',
                duration: -1,
                closeOnClick: false
            }
        );
    },

    addInstanceAndSavePlaylist(playlist, instance_id, root) {

        playlist.entries.push({
            id: null,
            instance: {
                id: instance_id
            },
            order: playlist.entries.length+1
        })

        this._savePlaylist(playlist, root, playlist.id)
    },

    _savePlaylist(playlist, root, id=0) {
        let url = id ? '/api/playlists/' + id + '/' : '/api/playlists/'
        let method = id ? 'PUT' : 'POST'
        axios({method: method, url: url, data: playlist})
        .then((result) => {
            // update context
            tdj2.fetchProfile()
            this.notifySuccessWithLink('Плейлист', 'Плейлист успешно сохранен',
                'Перейти к редактированию', root, '/playlists/'+result.data.id+'/');

        })
        .catch((error) => {
            this.notify_error('Ошибка сохранения плейлиста', error)
            console.log(error)
        })
    },

    _findInstanceInEntries(entries, instance_id, recording_id) {
        var found = 0;
        for (let e of entries) {
            if (e.instance.id === instance_id || e.instance.recording.id === recording_id) {
                found++;
            }
        }
        return found;
    },

    // player-related

    newPlaylist: function(pls) {
        console.log('newPlaylist()')
        this.state.playlist_pending = pls;
    },

    checkForPendingPlaylist: function() {
        console.log('checkForPendingPlaylist()')
        if (this.state.playlist_pending) {
            this.state.player_current_track = null;
            this.state.playlist = this.state.playlist_pending;
            this.state.playlist_pending = null;
            console.log('switched playlist to pending')
            console.log(this.state.playlist)
        }
    },

    playFile: function(url, root=null) {
        console.log('playFile(' + url +')' )
        this.checkForPendingPlaylist();
        let m = this.state.playlist.map(function(item) { return item[0]; })
        this.state.player_current_track = m.indexOf(url);
        if (root) {
            root.$emit('player-do-play', true);
        } else {
            console.log('unable to emit player-to-play, no root')
        }

    }

}
