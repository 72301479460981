<template>
    <div id="home">

        <b-modal ref="tag_form" size="lg" no-close-on-backdrop title="Тег" :ok-only="true">
            <div slot="modal-footer">

                <div class="btn-group">

                    <button v-if="current_tag.id" @click="deleteTag" class="btn btn-small btn-danger">Удалить</button>
                    <button  @click="saveTag" class="btn btn-small btn-primary">OK</button>

                </div>


            </div>
                <div class="container lp-inline-form">
                    <div class=" row">
                        <div class="col-sm-4 col"><label  for="tag_title">Заголовок</label></div>
                        <div class="col-sm-4col">
                            <b-form-input id="tag_title" type="text" v-model.trim="current_tag.title"></b-form-input>
                        </div>
                        <div class="col-sm-4 col"></div>
                    </div>
                     <div class="row">
                        <div class="col-sm-4 col">
                            <label for="tag_enabled_color">Цвет</label>
                        </div>
                        <div class="col-sm-4 col">
                            <input type="checkbox" class="lp-checkbox" id="tag_enabled_color" v-model.trim="enabled_color">&nbsp;
                            <div v-show="enabled_color" @click="doTogglePicker" id="tag_color" type="text" :style="{background: current_tag.color, cursor: 'pointer', width: '48px', height: '24px', display: 'inline-block', border: '1px solid #eeeeee'}"></div>
                        </div>
                        <b-form-text class="col-sm-4 col"></b-form-text>
                    </div>
                    <div class="colorpicker_container" style=" width: 220px;" v-show="showPicker">
                        <color-picker
                            :theme="theme"
                            :color="current_tag.color"
                            :sucker-hide="false"
                            :sucker-canvas="suckerCanvas"
                            :sucker-area="suckerArea"
                            @changeColor="changeColor"
                            @openSucker="openSucker"
                        />
                        <img v-if="isOpenSucker" ref="cover" />
                    </div>

                </div>

        </b-modal>

        <div v-if="tags_loaded" class="container-fluid">
            <h2>Теги</h2>

            <div v-if="!tags_changed" class="lp-info">
                <octicon name="light-bulb"></octicon> Теги -- это метки, которые можно прикреплять к тандам. Метки могут иметь цвет, назначение такой метки будет окрашивать танду.
            </div>

            <div v-if="tags_changed" class="lp-alert">
                <octicon name="alert"></octicon> Вы не сохранили теги после внесения изменений!
            </div>

            <div class="tags">
                <draggable v-model="tags" group="entries" @end="update">
                    <div @click="editTag(item)" v-for="item in tags" class="tag" :style="'background-color: ' + (item.color || '#eeeeee') + '; border-color: ' + (item.border_color || '#d5d5d5')">
                        <octicon name="tag"></octicon>
                        {{ item.title }}
                    </div>
                </draggable>
            </div>

            <div class="btn-group">

                <button :disabled="!tags_changed" class="btn btn-small btn-primary" @click="save">
                    <octicon name="cloud-upload"></octicon> сохранить
                </button>

                <button @click="addTag" class="btn btn-small btn-secondary">
                    <octicon name="diff-added"></octicon> добавить тег
                </button>

            </div>
        </div>

    </div>
</template>
<script>
    import { tdj2 } from '@/tdj2.js'
    import axios from 'axios'
    import colorPicker from '@caohenghu/vue-colorpicker'
    import draggable from 'vuedraggable'

    export default {
        name: 'tags',

        components: {
            colorPicker, draggable
        },

        data () {
            return {
                state: tdj2.state,
                tags: [],
                tags_loaded: false,
                tags_changed: false,
                current_tag: {
                    id: null,
                    title: '',
                    color: '',
                    order: 0
                },

                enabled_color: false,
                showPicker: false,

                theme: 'light',
                suckerCanvas: null,
                suckerArea: [],
                isOpenSucker: false,
            }
        },

        methods: {

            save() {
                axios.put('/api/tags/', this.tags)
                .then((result) => {
                    console.log('tag updated')
                    this.fetchTags()
                })
                .catch((error) => {
                    alert('Ошибка!')
                    console.log(error)
                })
            },

            update() {
                for(let i in this.tags) {
                    this.tags[i].order = parseInt(i) +1
                }
                this.tags_changed = true;
            },

            fetchTags() {
                axios.get('/api/tags/')
                .then((result) => {
                    this.tags = result.data
                    this.tags_loaded = true
                    this.tags_changed = false;
                    this.$root.$emit('lpTagsLoaded')
                    console.log('tags loaded')
                })
                .catch((error) => {
                    console.log(error)
                })
            },

            addTag() {
                this.current_tag = {
                    id: null,
                    title: '',
                    color: '',
                    order: 0
                }
                this.enabled_color = false
                this.$refs.tag_form.show()
            },

            editTag(tag) {
                this.current_tag = tag
                this.enabled_color = tag.color;
                this.$refs.tag_form.show()
            },

            deleteTag() {
                this.doHidePicker()
                let c = this.current_tag
                this.tags = this.tags.filter(function(value, index, arr){
                    return value.id !== c.id || value.title !== c.title;
                });
                this.update()
                this.tags_changed = true;
                this.$refs.tag_form.hide()
            },

            saveTag() {
                this.doHidePicker()
                if (!this.enabled_color) {
                    this.current_tag.color = '';
                }
                if (!this.current_tag.id) {
                    this.tags.push(this.current_tag);
                }
                this.tags_changed = true;
                this.update();
                // if (this.current_id) {
                //     axios.put('/api/tags/' + this.current_id + '/', this.current_tag)
                //     .then((result) => {
                //         console.log('tag updated')
                //         this.fetchTags()
                //     })
                //     .catch((error) => {
                //         console.log(error)
                //     })
                // } else {
                //     axios.post('/api/tags/', this.current_tag)
                //     .then((result) => {
                //         console.log('tag created')
                //         this.fetchTags()
                //     })
                //     .catch((error) => {
                //         console.log(error)
                //     })
                // }
                this.$refs.tag_form.hide()

            },

            doShowPicker() {
                this.showPicker = true
            },

            doTogglePicker() {
                this.showPicker = !this.showPicker
            },

            doHidePicker() {
                this.showPicker = false
            },

            changeColor(color) {
                const { r, g, b, a } = color.rgba
                this.current_tag.color = '#' +  this.rgbToHex(r) + this.rgbToHex(g) + this.rgbToHex(b)
                if (this.current_tag.id) {
                    this.tags_changed = true;
                }
            },

            rgbToHex (rgb) {
              var hex = Number(rgb).toString(16);
              if (hex.length < 2) {
                   hex = "0" + hex;
              }
              return hex;
            },

            openSucker(isOpen) {
                this.isOpenSucker = isOpen
                if (isOpen) {
                    setTimeout(() => {
                        const cover = this.$refs.cover
                        cover.onload = () => {
                            if (!this.isOpenSucker) {
                                return
                            }
                            const coverRect = cover.getBoundingClientRect()
                            const canvas = this.createCanvas(cover, coverRect)
                            document.body.appendChild(canvas)
                            this.suckerCanvas = canvas
                            this.suckerArea = [
                                coverRect.left,
                                coverRect.top,
                                coverRect.left + coverRect.width,
                                coverRect.top + coverRect.height
                            ]
                        }
                        cover.setAttribute('crossorigin', 'Anonymous')
                        cover.src = imgCover
                    }, 10)
                } else {
                    this.suckerCanvas && this.suckerCanvas.remove()
                }
            },
            createCanvas(cover, coverRect) {
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')
                canvas.width = coverRect.width
                canvas.height = coverRect.height
                ctx.drawImage(cover, 0, 0, coverRect.width, coverRect.height)
                Object.assign(canvas.style, {
                    position: 'absolute',
                    left: coverRect.left + 'px',
                    top: coverRect.top + 'px',
                    opacity: 0
                })
                return canvas
            },
        },

        created () {

            this.fetchTags();

        }

    }
</script>
<style lang="less">

    .tags {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 30px;

        .tag {
            cursor: pointer;
            padding: 5px 7px;
            margin-bottom: 5px;
            border: 1px solid  #DDDDDD;
            width: auto;
            width: 300px;
        }
    }

    .cover {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 220px;
    }

    .colorpicker_container {
        position: absolute;
        left: 290px;
    }

    .hu-color-picker {
        box-sizing: content-box;
    }

    div.color-alpha {
        display: none;
    }

</style>