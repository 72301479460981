<template>

    <div class="container-fluid downloads">

        <h2>Моё</h2>

        <h3>Рабочие пространства</h3>

        <p>
            Все ваши танды, плейлисты и сеты хранятся в рабочих пространствах.
            Если вы чувствуете что хотели бы начать собирать материал с чистого листа -- то всегда можно создать новое рабочее пространство и переключиться на работу в нём.<br/>
            Для того чтобы переключиться на другое рабочее пространство, щелкните на его названии.
        </p>

        <div class="workspaces">
            <table class="workspaces">
                <tr>
                    <th>Название</th>
                    <th>Танд</th>
                    <th>Сетов</th>
                    <th>Плейлистов</th>
                    <th></th>
                </tr>
                <tr class="ws" v-for="ws in state.workspaces" :key="ws.id" :class="{'active': ws.id === state.profile.current_workspace}">
                    <td @click="switchWS(ws)" class="title" :class="{'clickable': ws.id !== state.profile.current_workspace}">{{ ws.name }}</td>
                    <td>{{ ws.tandas_count }}</td>
                    <td>{{ ws.djsets_count }}</td>
                    <td>{{ ws.playlists_count }}</td>
                    <td class="lp-actions">
                        <a title="Переименовать рабочее пространство" class="clickable" @click="renameWS(ws)" ><octicon name="pencil"></octicon></a>
                        <a title="Удалить рабочее пространство" @click="deleteWS(ws)" v-if="ws.id !== state.profile.current_workspace"><octicon name="trashcan"></octicon></a>
                    </td>
                </tr>
            </table>

        </div>

        <h3>Создать рабочее пространство</h3>
        <form @submit="createWS" class="create_ws">
            <div class="input-group" style="width: 500px;">
                <input type="text" class="form-control form-control-sm" v-model.trim="new_ws_name"/>
                <button type="submit" class="btn btn-sm btn-primary">Создать</button>
            </div>
        </form>

        <h3>Скачать</h3>

        <div class="entry" v-if="state.profile.favorite_instances.length > 0">
            <a @click="download_favorites" class="clickable"><octicon name="cloud-download"/> скачать избранное</a> ({{ state.profile.favorite_instances.length }})
        </div>

        <div class="entry" v-if="state.profile.context.tandas_count > 0">
            <a @click="download_tandas" class="clickable"><octicon name="cloud-download"/> скачать танды</a> ({{ state.profile.context.tandas_count }})
        </div>

        <div class="entry" v-if="state.profile.context.playlists_count > 0">
            <a @click="download_playlists" class="clickable"><octicon name="cloud-download"/> скачать плейлисты</a> ({{ state.profile.context.playlists_count }})
        </div>

        <div class="entry" v-if="state.profile.context.djsets_count > 0">
            <a @click="download_djsets" class="clickable"><octicon name="cloud-download"/> скачать сеты</a> ({{ state.profile.context.djsets_count }})
        </div>

        <div class="entry everything">
            <a @click="download_everything" class="clickable"><octicon name="cloud-download"/> скачать всё</a>
        </div>

    </div>

</template>
<script>

    import axios from 'axios';
    import {tdj2} from "@/tdj2";

    export default {
        name: 'My',
        data() {
            return {
                state: tdj2.state,
                new_ws_name: ''

            }
        },
        methods: {
            renameWS(ws) {
                var new_name = prompt('Новое имя рабочего пространства?', ws.name);
                if (new_name) {
                    ws.name = new_name
                    axios({method: 'PUT', url: '/api/workspaces/'+ws.id+'/', data: ws})
                    .then((result) => {
                        // update context
                        tdj2.fetchWorkspaces(this.$root)
                        tdj2.notify_success('Рабочее пространство сохранено');
                    })
                    .catch((error) => {
                        tdj2.notify_error('Ошибка сохранения рабочего пространства', error)
                        console.log(error)
                    })
                }
            },
            deleteWS(ws) {
                if (ws.tanda_count > 0 || ws.djsets_count > 0 || ws.playlists_count > 0) {
                    alert('Нельзя удалять непустое рабочее пространство')
                    return;
                }
                if (!confirm('Удалить рабочее пространство ' + ws.name + '?')) {
                    return;
                }
                axios({method: 'DELETE', url: '/api/workspaces/'+ws.id+'/'})
                .then((result) => {
                    // update context
                    tdj2.fetchWorkspaces(this.$root)
                    tdj2.notify_success('Рабочее пространство удалено');
                })
                .catch((error) => {
                    tdj2.notify_error('Ошибка удаления рабочего пространства', error)
                    console.log(error)
                })
            },
            switchWS(ws) {
                if (ws.id === tdj2.state.profile.current_workspace) {
                    return
                }
                this.state.profile.current_workspace = ws.id;
                tdj2.saveProfile(this.$root, false, true)

            },
            createWS(event) {
                event.preventDefault();
                tdj2.startWorkspace(this.new_ws_name, this.$root)
                this.new_ws_name = '';
            },
            download_favorites() {
                tdj2.download_file('/api/download/favorites/')
            },
            download_tandas() {
                tdj2.download_file('/api/download/tandas/')
            },
            download_playlists() {
                tdj2.download_file('/api/download/playlists/')
            },
            download_djsets() {
                tdj2.download_file('/api/download/djsets/')
            },
            download_everything() {
                tdj2.download_file('/api/download/everything/')
            }
        }
    }

</script>
<style lang="less">
    .downloads {
        .entry {

            &.everything {
                padding-top: 10px;
            }
        }
    }

    .workspaces {
        margin-bottom: 30px;
        th {
            color: #999999;
            font-size: 9pt;
            text-align: left;
        }
        th, td {
            padding-left: 10px;
            padding-top: 2px;
            padding-bottom: 2px;
            padding-right: 10px;
        }
        .title {
            font-weight: bold;
        }
        tr.active td {
            background-color: #5c6bc0;
            color: white;
            .octicon {
                color: white;
            }
        }
        .lp-actions {
            a {
                margin-right:5px;
                cursor: pointer;
            }
        }
    }

    form.create_ws {
        margin-bottom: 30px;
    }

</style>