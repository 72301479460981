<template>
    <v-footer dark padless absolute>
        <v-card flat tile class="indigo lighten-1 white--text text-center" style="background-color: #5c6bc0; width: 100%;">

            <div class="footer_player">
                <bottom-player :bottom_margin="66" />
            </div>

            <v-divider></v-divider>

            <v-card-text class="white--text" style="padding-top: 0px;">
            &copy; 2018-{{ new Date().getFullYear() }} <strong>tangodj.online</strong>, <a href="/pages/privacy/" target="_blank" style="color:white">политика приватности</a>
            </v-card-text>
        </v-card>
    </v-footer>
</template>
<script>

    import BottomPlayer from "@/components/player/BottomPlayer";

  export default {
        name: 'Footer',

        components: {BottomPlayer},

    }

</script>
<style lang="less">

    body.modal-open .footer_player {
        display: none;
    }

    hr.v-divider {
        margin-top: 10px;
    }

    footer {
        /*box-shadow: 0px 0px 2px rgb(0, 0, 0, 0.5);*/
    }

</style>