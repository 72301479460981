<template>
    <section class="pager">
        <template v-if="currentListing.page > 1">
            <button class="btn btn-primary btn-sm" @click="goToHook(1)">
                <octicon name="chevron-left"></octicon>
                <octicon name="chevron-left"></octicon>
            </button>
            <button class="btn btn-primary btn-sm" @click="goToHook(currentListing.page-1)">
                <octicon name="chevron-left"></octicon>
            </button>
        </template>
        <template v-if="currentListing.page === 1">
            <button class="btn btn-sm btn-secondary" disabled>
                <octicon name="chevron-left"></octicon>
                <octicon name="chevron-left"></octicon>
            </button>
            <button class="btn btn-sm btn-secondary" disabled>
                <octicon name="chevron-left"></octicon>
            </button>
        </template>
        <div class="pager-text">Страница <b>{{ currentListing.page }}</b> из <b>{{ currentListing.pages }}</b></div>
        <template v-if="currentListing.page < currentListing.pages">
            <button class="btn btn-primary btn-sm" @click="goToHook(currentListing.page+1)">
                <octicon name="chevron-right"></octicon>
            </button>
            <button class="btn btn-primary btn-sm" @click="goToHook(currentListing.pages)">
                <octicon name="chevron-right"></octicon><octicon name="chevron-right"></octicon>
            </button>
        </template>
        <template v-if="currentListing.page == currentListing.pages">
            <button class="btn btn-secondary btn-sm" disabled>
                <octicon name="chevron-right"></octicon>
            </button>
            <button class="btn btn-secondary btn-sm" disabled>
                <octicon name="chevron-right"></octicon>
                <octicon name="chevron-right"></octicon>
            </button>
        </template>
    </section>
</template>
<script>
    export default {
        name: 'Pager',
        props: {
            currentListing: {
                page: 1,
                pages: 1,
            },
            goToHook: null
        }
    }
</script>
<style lang="less">

    .pager{
        margin-bottom: 30px;
        button {
            margin-left: 3px;
            margin-right: 3px;
        }
        .pager-text {
            display: inline-block;
            margin-right: 6px;
            margin-left: 6px;
        }
    }

</style>