<template>

    <div class="player_progress" ref="container" @click="goto_position">

        <div class="background" v-if="state.player_current_track != null"></div>
        <div class="preload" ref="preload" v-show="state.player_current_track != null"></div>
        <div class="progress" ref="progress" v-show="state.player_current_track != null"></div>
        <div class="stripes"></div>
        <div class="overlay" v-if="state.player_current_track != null" ref="overlay" :style="'background-image: url(\'' + state.playlist[state.player_current_track][2]['indigo-inverted'] + '\');'" ></div>

    </div>

</template>
<script>

    import { tdj2 } from "@/tdj2";

    export default {

        name: 'PlayerProgress',

        data() {
            return {
                state: tdj2.state
            }
        },

        created() {

        },

        mounted() {
            console.log(this.$refs.container)
            console.log(this.$refs.progress)
            this.$root.$on('player-playing', this.update_progress)
        },

        methods: {
            update_progress(progress, time, preload, duration) {
                if (!this.$refs.container) {
                    return;
                }
                let sc_width = this.$refs.container.offsetWidth - 2;
                this.$refs.preload.style.width = Math.round(preload / duration * sc_width) + 'px';
                this.$refs.progress.style.width = Math.round(progress * sc_width) + 'px';
            },
            goto_position(event) {
                console.log(this.$refs.container.getBoundingClientRect().left)
                let progress = (event.pageX - this.$refs.container.getBoundingClientRect().left) / this.$refs.container.offsetWidth;
                console.log('new progress ' + progress)
                this.$root.$emit('player-goto', progress)
                this.update_progress(progress)
            }
        },

        destroyed() {

        }

    }

</script>
<style lang="less">

    .player_progress {
        background-color: #5c6bc0;
        width: 100%;
        height: 100%;
        /*border: 1px solid #d0d5f1;*/

        .progress {
            position: absolute;
            background-color: #d0d5f1;
            border-radius: 0px;
            height: 100%;
        }

        .preload {
            background-color: #8b96d2;
            height: 100%;
            position: absolute;
            width: 0px;
        }

        .stripes {
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url('/stripe-bg-indigo-25.png');
            background-repeat: repeat;
        }

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        .background {
            position: absolute;
            background-color: #717ec8;
            width: 100%;
            height: 100%;
        }

    }

</style>