<template>
    <recordings-page mode="search" :page="page" :query="query" />
</template>

<script>
    import 'vue-octicon/icons'


    import RecordingsPage from "@/components/RecordingsPage";

    export default {

        components: {RecordingsPage},
        props: ['page', 'query'],
        name: 'search',

    }
</script>

<style lang="less">

</style>
