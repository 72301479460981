<template>

    <div class="bottom_player" :class="detached || always_detached ? 'detached detached-'+state.profile.bottom_player_size : ''">
        <player-core v-if="!slave" />
        <player-progress />
        <player-controls />
        <player-title />
        <player-time />
    </div>

</template>
<script>

    import PlayerCore from "@/components/player/PlayerCore";
    import PlayerProgress from "@/components/player/PlayerProgress";
    import PlayerControls from "@/components/player/PlayerControls";
    import PlayerTime from "@/components/player/PlayerTime";
    import PlayerTitle from "@/components/player/PlayerTitle";
    import { tdj2 } from "@/tdj2";

    export default {
        name: 'BottomPlayer',
        components: {PlayerTitle, PlayerTime, PlayerControls, PlayerProgress, PlayerCore},
        props: {
            bottom_margin: {
                type: Number,
                default: 0
            },
            slave: {
                type: Boolean,
                default: false
            },
            always_detached: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                state: tdj2.state,
                scroll_limit: 0,
                detached: false
            }
        },
        created() {
            this.setScrollLimit()
            this.$root.$on('lpProfileLoaded', this.setScrollLimit)
            if (!this.always_detached) {
                this.$root.$on('lpUpdateHeight', this.onScroll)
                this.$nextTick(function() {
                    window.addEventListener('resize', this.onScroll);
                    window.addEventListener('scroll', this.onScroll);
                });
            }
        },
        watch:{
            $route (to, from){
                this.onScroll()
            }
        },
        methods: {
            setScrollLimit() {
                this.scroll_limit = this.bottom_margin
                if (this.state.profile.bottom_player_size === 0) {
                    this.scroll_limit += 35;
                } else if (this.state.profile.bottom_player_size === 1) {
                    this.scroll_limit += 17;
                }
            },
            onScroll() {
                let offset = document.body.scrollHeight - window.innerHeight - window.scrollY;
                this.detached = (offset > this.scroll_limit);
            }
        }
    }

</script>
<style lang="less">

    .bottom_player {
        width: 100%;
        height: 100px;
        background-color: #5c6bc0;

        &.detached {
            position: fixed;
            left:0px;
            width: 100%;
            bottom: 0px;
            &.detached-0 {
                bottom: -35px;
            }
            &.detached-1 {
                bottom: -17px;
            }
        }

        .player_progress {
            position: absolute;
            height: 70px;
            width: 100%;
            margin-top: 30px;
            padding-left: 5px;
            padding-right: 5px;
        }

        .player_controls {
            position: absolute;
            margin-left: 3%;
            margin-top: 2px;
            width: 100px;
            height: 33px;
            color: white;
            svg {
                -webkit-filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, .7));
                filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, .7));
            }
        }

        .player_title {
            position: absolute;
            margin-top: 5px;
            margin-left: calc(3% + 100px);
            width: calc(94% - 200px);
            text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.7);
            color: white;
            text-align: center;
        }

        .player_time {
            margin-top: 5px;
            position: absolute;
            right: 3%;
            width: 100px;
            text-align: right;
            text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.7);
            color: white;
        }

    }

</style>