<template>
    <div id="home">
        <div v-if="tandas_loaded" class="container-fluid">
            <h2>Танды</h2>

            <p>Упорядочить по:
                <span class="inline-radio"><input type="radio" v-model="group_by" @click="save_mode('orchestra')" name="group_by" value="orchestra" id="gpb_orchestra"/> <label for="gpb_orchestra">по оркестру</label></span>
                <span class="inline-radio"><input type="radio" v-model="group_by" @click="save_mode('genre')" name="group_by" value="genre" id="gpb_genre"/> <label for="gpb_genre">по жанру</label></span>
                <span class="inline-radio"><input type="radio" v-model="group_by" @click="save_mode('tag')" name="group_by" value="tag" id="gpb_tag"/> <label for="gpb_tag">по тегу</label></span>
            </p>

            <tanda-list :tandas="tandas" :group_by="group_by" mode="list" :delete-tanda="deleteTanda" />

            <div>
                <button :disabled="tandas.length < 1" class="btn btn-small btn-success" @click="download_tandas">
                    <octicon name="cloud-download"></octicon> скачать все танды
                </button>
            </div>

        </div>
    </div>

</template>
<script>

    import { tdj2 } from '@/tdj2.js'
    import axios from 'axios'
    import TandaList from "@/components/TandaList";

    export default {

        name: 'tandas',
        props: ['page'],
        components: {TandaList},

        data() {
            return {
                state: tdj2.state,
                tandas: [],
                tandas_loaded: false,
                group_by: 'orchestra'
            }
        },

        created() {
            this.read_cookies();
            this.fetchTandas()
        },

        methods: {
            read_cookies() {
                let new_group_by = tdj2.getParam('TandasGroupBy', this.group_by);
                if (['orchestra', 'genre', 'tag'].includes(new_group_by)) {
                    this.group_by = new_group_by;
                }
            },
            save_mode(mode) {
                tdj2.setParam('TandasGroupBy', mode);
            },
            fetchTandas() {
                axios.get('/api/tandas/')
                .then((result) => {
                    this.tandas = result.data
                    this.tandas_loaded = true;
                    this.$root.$emit('lpTandaListUpdated')
                })
                .catch((error) => {
                    console.log(error)
                })
            },
            deleteTanda(t) {
                if (confirm('Удалить танду ' + t.title + '?')) {
                axios.delete('/api/tandas/' + t.id + '/')
                .then((result) => {
                    this.fetchTandas()
                    tdj2.fetchProfile()
                })
                .catch((error) => {
                    console.log(error)
                    alert('Ошибка')
                })
                }
            },

            download_tandas() {
                tdj2.download_file('/api/download/tandas/')
            }
        }

    }

</script>
<style lang="less">

.inline-radio {
    display: inline-block;
    margin-left: 10px;
    input {
        margin-right: 5px;
    }
}

</style>