<template>
    <div id="header">
        <b-navbar toggleable="md" type="light" variant="halftransparent1"  class="align-items-end">
            <div class="container-fluid">
                <b-navbar-brand id="logo" to="/">
                    <h3>tangodj.online</span></h3>
                </b-navbar-brand>

                <b-navbar-toggle target="header_collapse"></b-navbar-toggle>

                <b-collapse is-nav id="header_collapse" v-model="menuOpen">
                    <b-navbar-nav class="menu">
                        <b-nav-item to="/orchestras/">Оркестры</b-nav-item>
                        <b-nav-item to="/singers/">Певцы</b-nav-item>
                        <b-nav-item to="/genres/">Жанры</b-nav-item>
                        <b-nav-item to="/favorites/">Избранное</b-nav-item>
                        <b-nav-item to="/tags/">Теги</b-nav-item>
                        <b-nav-item to="/tandas/">Танды</b-nav-item>
                        <b-nav-item to="/sets/">Сеты</b-nav-item>
                        <b-nav-item to="/playlists/">Плейлисты</b-nav-item>
                        <b-nav-item to="/my/">Моё</b-nav-item>
                    </b-navbar-nav>

                    <b-navbar-nav class="ml-auto login" >
                        <b-nav-item><octicon name="person"></octicon> {{ state.profile.first_name }}<span class="d-md-none d-lg-inline"> {{ state.profile.last_name }}</span></b-nav-item>
                        <b-nav-item @click="$refs.profile_modal.show()"><octicon name="gear"></octicon></b-nav-item>
                        <b-nav-item @click="logout"><octicon name="sign-out"></octicon></b-nav-item>
                    </b-navbar-nav>

                </b-collapse>
            </div>
        </b-navbar>

        <div class="searchWidget">
            <form @submit="do_search($event)">
               <div class="input-group" style="margin-bottom: 20px; margin-top:20px;">
                    <input type="text" v-model.trim="query" class="form-control form-control-sm" placeholder="Поиск">
                        <button type="submit" class="btn btn-sm btn-info"><octicon name="search"></octicon></button>
                </div>
            </form>
        </div>

        <b-modal ref="profile_modal" size="lg" no-close-on-backdrop title="Настройки" :ok-only="true">
            <div slot="modal-footer" style="display:none">ok</div>

            <b-card no-body>
                <b-tabs pills card>

                    <b-tab title="Каталог">
                        <div class="container lp-inline-form">
                            <div class="form-group row">
                                <label class="col-sm-6" for="profile_years_limit_low">Скрывать записи до года</label>
                                <b-form-input  class="col-sm-2" id="profile_years_limit_low" type="text" v-model.trim="state.profile.years_limit_low"></b-form-input>
                                <b-form-text class="col-sm-4">0 = не ограничивать</b-form-text>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6" for="profile_years_limit_high">Скрывать записи после года</label>
                                <b-form-input  class="col-sm-2" id="profile_years_limit_high" type="text" v-model.trim="state.profile.years_limit_high"></b-form-input>
                                <b-form-text class="col-sm-4">0 = не ограничивать</b-form-text>
                            </div>
                        </div>
                    </b-tab>

                    <b-tab title="Интерфейс">
                        <div class="container lp-inline-form">
                            <div class="form-group row">
                                <label class="col-sm-6 col" for="profile_items_per_page">Записей на странице</label>
                                <b-form-input class="col-sm-2 col" id="profile_items_per_page" type="text" v-model.trim="state.profile.items_per_page"></b-form-input>
                                <b-form-text class="col-sm-4 col"></b-form-text>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col" for="profile_player_size">Размер плейера внизу страницы</label>
                                <select  class="form-control col-sm-6 col" id="profile_player_size" v-model="state.profile.bottom_player_size">
                                    <option value="0">Маленький (65px)</option>
                                    <option value="1">Средний (83px)</option>
                                    <option value="2">Большой (100px)</option>
                                </select>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12"><b-form-checkbox  id="profile_recordings_collapsed" v-model.trim="state.profile.recordings_collapsed">Сворачивать несколько экземпляров одной записи</b-form-checkbox></div>
                            </div>
                        </div>
                    </b-tab>

                    <b-tab title="Синхронизация">
                        <div class="container lp-inline-form">
                            <div class="form-group row">
                                <b-form-text class="col-sm-3 col">Ваш ID</b-form-text>
                                <b-form-text class="col-sm-9 col">{{ state.profile.context.sync_id }}</b-form-text>
                            </div>
                            <div class="form-group row">
                                <b-form-text class="col-sm-3 col">Ваш Token</b-form-text>
                                <b-form-text class="col-sm-9 col">{{ state.profile.context.sync_token }}</b-form-text>
                            </div>
                            <div class="form-group row">
                                <b-form-text class="col-sm-3 col">Скачать клиент</b-form-text>
                                <b-form-text class="col-sm-9 col"><a class="btn btn-sm btn-info" href="/static/sync/tdj2_sync_client.zip"><octicon name="cloud-download"></octicon> tdj_sync_client.zip</a></b-form-text>
                            </div>
                        </div>
                    </b-tab>

                </b-tabs>

            </b-card>

            <p style="text-align: right; margin-top: 20px;"><b-button @click="saveProfile" variant="primary small">Сохранить</b-button></p>

        </b-modal>

    </div>
</template>


<script>

    import { tdj2 } from '@/tdj2.js'
    import axios from 'axios'

    export default {

        data () {
            return {
                state: tdj2.state,
                menuOpen: false,
                query: ''
                //
                // loading: false,
                // accepted: false,
                //

                //
                // feedback: '',
                // openedIndex: -1,
            }
        },

        methods: {

            do_search($event) {
                $event.preventDefault();
                if (!this.query) {
                    return
                }
                this.$router.push({ name: 'search_query', params: { query: this.query } })
            },

            logout () {
                tdj2.logout()
            },

            saveProfile () {
                this.$root.$on('lpProfileSaved', this.$refs.profile_modal.hide)
                tdj2.saveProfile(this.$root)
            },

            onScroll (evt) {
                this.menuOpen = (evt.wheelDelta <= 0)
            }

        },

        computed: {

        },

        created () {
            window.addEventListener('scroll', this.onScroll)
        },

        destroyed () {
            window.removeEventListener('scroll', this.onScroll)
        }

    }
</script>

<style scoped lang="less">

    .searchWidget {
        position: absolute;
        right: 3%;
        input, button {
            font-size: 10pt;
        }
    }

    #header {
        border-bottom: 5px solid #5c6bc0;

        .octicon {
            vertical-align: text-top !important;
        }

    }

    .lp-inline-form select.form-control {
        padding: 0px 10px;
    }

</style>