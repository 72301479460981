<template>

    <b-modal ref="dialog" size="xl" no-close-on-backdrop no-enforce-focus no-close-on-esc title="Добавить танду" @close="$root.$emit('lpUpdateHeight')" :ok-only="true">

        <bottom-player :slave="true" :always_detached="true" />

            <p>Упорядочить по:
                <span class="inline-radio"><input type="radio" v-model="group_by" @click="save_mode('orchestra')" name="group_by" value="orchestra" id="gpb_orchestra"/> <label for="gpb_orchestra">по оркестру</label></span>
                <span class="inline-radio"><input type="radio" v-model="group_by" @click="save_mode('genre')" name="group_by" value="genre" id="gpb_genre"/> <label for="gpb_genre">по жанру</label></span>
                <span class="inline-radio"><input type="radio" v-model="group_by" @click="save_mode('tag')" name="group_by" value="tag" id="gpb_tag"/> <label for="gpb_tag">по тегу</label></span>
            </p>

        <div v-if="data_loaded">
            <TandaList ref="listing" :tandas="data" :group_by="group_by" mode="add" :add-hook="add" :used_ids="used_tanda_ids" />
        </div>
    </b-modal>

</template>
<script>

    import { tdj2 } from "@/tdj2";
    import axios from 'axios'
    import TandaList from "./TandaList";
    import BottomPlayer from "@/components/player/BottomPlayer";

    export default {

        name: 'AddTandaDialog',

        components: {BottomPlayer, TandaList},

        props: {
            djset: {
                default: function() { return {
                    entries: [],
                    length: 0
                }},
                type: Object
            },
            addHook: null,
        },

        data() {
            return {
                state: tdj2.state,
                playlist_version: 0,
                group_by: 'orchestra',

                now_loading: false,
                data_loaded: false,
                data: {

                }
            }
        },

        created() {
            this.read_cookies();
            this.$root.$on('open_add_tanda', this.open);
        },

        computed: {
            used_tanda_ids() {
                let ids = [];
                for (let e of this.djset.entries) {
                    ids.push(e.tanda.id);
                }
                return ids;
            }
        },

        methods: {
            read_cookies() {
                let new_group_by = tdj2.getParam('DLGAddTandaGroupBy', this.group_by);
                if (['orchestra', 'genre', 'tag'].includes(new_group_by)) {
                    this.group_by = new_group_by;
                }
            },
            save_mode(mode) {
                tdj2.setParam('DLGAddTandaGroupBy', mode);
            },
            open() {
                console.log('add open')
                this.load_data()
                this.$refs.dialog.show();
                this.$root.$emit('lpUpdateHeight')
            },
            add(tanda) {
                if (this.addHook) {
                    this.addHook(tanda)
                }
                this.$refs.dialog.hide()
            },


            load_data() {
                this.get_data('/api/tandas/')
            },

            get_data(url) {
                this.data_loaded = false;
                this.now_loading = true;
                axios.get(url)
                .then((result) => {
                    this.now_loading = false;
                    this.data_loaded = true;
                    this.data = result.data;
                    if (this.$refs.listing) {
                        this.$refs.listing.$emit('ListingChanged')
                    }
                    this.$emit('lpListingLoaded'); // todo: is it work ?
                })
                .catch((error) => {
                    console.log(error)
                    alert('Ошибка загрузки данных')
                    this.now_loading = false;
                })
            }



        }
    }

</script>
<style lang="less">

    @media (min-width: 1200px) {
        .modal-xl {
            max-width: 85%;
        }
        .modal-header {
            padding: 1rem 2rem;
        }
        .modal-body {
            padding: 1rem 2rem;
        }
        .modal-footer {
            padding: 1rem 2rem;
        }
    }

    .now_loading {
        padding: 50px;
        background-color: #eeeeee;
        margin: 10px;
        text-align: center;
    }

    .tandapreview td {
        padding: 5px;
    }

</style>