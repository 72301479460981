<template>

    <div class="player_time" v-if="state.player_current_track != null">
        {{ currentTime }} / {{ totalTime }}
    </div>

</template>
<script>

    import {tdj2} from "@/tdj2";

    export default {
        name: 'PlayerTime',
        data() {
            return {
                state: tdj2.state,
                currentTime: '',
                totalTime: ''
            }
        },
        created() {
            this.$root.$on('player-playing', this.update_progress)
        },
        methods: {

            update_progress(progress, time, preload, duration) {
                this.currentTime = tdj2.formatTime(time)
                this.totalTime = tdj2.formatTime(duration || 0)
            },
        }
    }

</script>
<style>

</style>