<template>

    <div v-if="comments_loaded" class="instance_info">

        <transition name="modal" >
            <div class="modal-mask" v-show="showModal">
                <div class="modal-wrapper">
                    <div class="modal-container">

                        <h4>Комментарии</h4>
                        <b>Файл: {{ instance.file.play_title }}</b>

                        <div class="comments">
                            <div class="comment" v-for="comment in comments">
                                <div class="header">От <b>{{ comment.user }}</b>, {{ comment.date_posted }}</div>
                                <div class="body">
                                    {{ comment.text }}
                                </div>
                            </div>
                            <div class="empty" v-if="comments.length === 0">Комментариев пока нет, ваш будет первым</div>
                        </div>

                        <h4>Новый комментарий</h4>

                        <form @submit="postComment">
                            <div class="new_comment">
                                <textarea class="form-control" placeholder="Введите текст комментария" v-model.trim="new_comment"></textarea>
                            </div>
                        </form>

                        <div style="padding-top: 20px;" class="btn-group">
                            <button class="btn btn-small btn-primary" :disabled="!new_comment" @click="postComment">Добавить комментарий</button>
                            <button class="btn btn-small btn-secondary" @click="hide">Закрыть</button>
                        </div>

                    </div>
                </div>
            </div>
        </transition>
    </div>

</template>
<script>

    import { tdj2 } from "@/tdj2";
    import axios from 'axios'

    export default {

        name: 'InstanceComments',
        data() {
            return {
                instance: {},
                comments: [],
                comments_loaded: false,
                new_comment: '',
                showModal: false
            }
        },
        created() {
            this.$root.$on('lpShowInstanceComments', this.showInstance)
        },
        methods: {
            postComment(event) {
                if (!this.new_comment) {
                    alert('Введите текст комментария')
                    return;
                }
                console.log('postComment')
                event.preventDefault()
                axios.post('/api/comments/', {
                    instance: this.instance.id,
                    text: this.new_comment
                })
                .then((result) => {
                    this.new_comment = '';
                    this.fetchComments(this.instance.id)
                    tdj2.notify_success('Комментарий успешно сохранен')
                }).catch(error => {
                    tdj2.notify_error('Ошибка загрузки комментариев', error)
                    this.showModal = false;
                })
            },
            hide() {
                this.showModal = false;
            },
            showInstance(instance) {
                this.instance = instance;
                this.fetchComments(instance.id)
            },
            fetchComments(id) {
                axios.get('/api/comments/?instance=' + id )
                .then((result) => {
                    this.comments = result.data;
                    this.$root.$emit('lpSetInstanceCommentCount', id, this.comments.length)
                    this.comments_loaded = true;
                    this.showModal = true;
                }).catch(error => {
                    tdj2.notify_error('Ошибка загрузки комментариев', error)
                    this.showModal = false;
                })
            },
        }
    }

</script>
<style lang="less">

    .comments {
        .comment {
            font-size: 10pt;
            padding: 10px;
            padding-left: 10px;
            background-color: #f0f0f0;
            border-radius: 5px;
            margin-bottom: 10px;
        }
        .empty {
            padding: 10px;
            padding-left: 10px;
            background-color: #eeeeee;
            border-radius: 5px;
        }
        margin-bottom: 20px;
        margin-top: 20px;
    }

.new_comment {
    padding: 5px;
    textarea {
        width: 100%;
        height: 64px;
    }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: top;
}

.modal-container {
  width: 800px;
  margin: 30px auto;
  padding: 30px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  /*font-family: Helvetica, Arial, sans-serif;*/
    max-height: calc(100vh - 60px);
    overflow-y: scroll;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>