<template>
    <div id="home">
        <div v-if="state.singers_loaded" class="container-fluid">
            <h2>Певцы</h2>
            <ul class="lp-large">
                <li v-for="g in state.singers">
                    <router-link class="orchestra" :to="'/singers/' + g.id">{{ g.name }}</router-link> ({{ g.recordings }})
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { tdj2 } from '@/tdj2.js'

import axios from 'axios'

export default {
  name: 'genres',

  created () {
  },

  data () {
    return {
      state: tdj2.state,
    }
  },

  components: {
  }
}
</script>

<style lang="less">

</style>
