<template>
    <div id="home">
        <div v-if="state.orchestras_loaded" class="container-fluid">

            <h2>Оркестры</h2>

            <section class="orchestras" v-if="state.orchestras_classic.length > 0">
                <h5>Классические</h5>
                  <ul class="lp-large">
                <li v-for="g in state.orchestras_classic">
                    <router-link class="orchestra" :to="'/orchestras/' + g.id">{{ g.name }}</router-link> ({{ g.recordings }})
                </li>
            </ul>
            </section>

            <section class="orchestras" v-if="state.orchestras_modern.length > 0">
                <h5>Современные</h5>
                              <ul class="lp-large">
                <li v-for="g in state.orchestras_modern">
                    <router-link class="orchestra" :to="'/orchestras/' + g.id">{{ g.name }}</router-link> ({{ g.recordings }})
                </li>
            </ul>
            </section>


        </div>
    </div>
</template>

<script>
    import { tdj2 } from '@/tdj2.js'

import axios from 'axios'

export default {
  name: 'orchestras',

  created () {

  },

  data () {
    return {
      state: tdj2.state,
    }
  },

  components: {
  }
}
</script>

<style lang="less">

</style>
