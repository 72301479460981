<template>
    <table class="tandas">

        <template v-for="(group, g_index) in Object.keys(groups)" class="group">
            <tr><td class="group" colspan="9">{{ group }}</td></tr>

            <tr v-if="!used_ids.includes(tanda.id) || mode !== 'add'" v-for="(tanda, innerIndex) in groups[group]"  class="tanda" :class="{'completed': tanda.completed, 'approved': tanda.approved, 'colored': tanda.color}"
                :style="'background-color: ' + (tanda.color ? tanda.color.color : 'auto') + '; border-top: ' + (tanda.color ? '1px solid ' + tanda.color.border_color  : '0px') + ';'"
            >
                <td v-show="group_by !== 'genre'"><span v-if="tanda.genre">{{ tanda.genre.name }}</span></td>
                <td class="orchestra" v-show="group_by !== 'orchestra'">
                    <span v-if="tanda.orchestra">{{ tanda.orchestra.shortname }}</span>
                    <span v-if="!tanda.orchestra">MIXED</span>
                </td>
                <td class="singers">
                    <span v-for="(singer, s_index) in tanda.singers"><span v-if="s_index > 0">/</span>{{ singer.shortname }}</span>
                </td>
                <td class="years"><span v-if="tanda.years_from && tanda.years_till">{{ tanda.years_from }}-{{ tanda.years_till }}</span></td>
                <td>
                    <span v-if="tanda.entries">
                        <span v-for="(entry, index) in tanda.entries"><span v-if="index > 0"> / </span><a :href="entry.instance.file.play_url" onclick="return false;" @click="playFile" class="playable">{{ entry.instance.recording.song.title }}</a></span>
                    </span>
                </td>
                <td class="length">{{ tanda.length_hr }}</td>
                <td class="lp-actions">
                    <template v-if="mode === 'list'">
                        <router-link :to="'/tandas/' + tanda.id + '/'"><a title="Редактировать танду"><octicon name="pencil"></octicon></a></router-link>
                        <a title="Скачать танду" :href="'/api/download/tandas/' + tanda.id + '/'"><octicon name="cloud-download"></octicon></a>
                        <a title="Удалить танду" @click="deleteTanda(tanda)"><octicon name="trashcan"></octicon></a>
                    </template>
                    <template v-if="mode === 'add'">
                        <a @click="addHook(tanda)" class="add add-primary clickable">добавить</a>
                    </template>
                </td>
                <td class="tags">
                    <span v-for="tag in tanda.tags" :key="tag.id" class="tandatag tandatag-custom"
                          :style="'background-color: ' + (tag.color || '#eeeeee') + '; border-color: ' + (tag.border_color || '#d5d5d5')">
                        <octicon name="tag"/> {{ tag.title }}
                    </span>
                    <span v-if="tanda.completed" class="tandatag tandatag-completed"><octicon name="check" /></span>
                    <span v-if="tanda.approved" class="tandatag tandatag-approved"><octicon name="thumbsup" /></span>
                    <span v-if="tanda.use_for_radio" class="tandatag tandatag-radio"><octicon name="radio-tower" /></span>
                </td>
            </tr>
        </template>
    </table>
</template>
<script>

    import { tdj2 } from "@/tdj2";

    export default {
        name: 'TandaList',
        props: {
            tandas: {
                type: Array,
                default: function() {
                    return []
                }
            },
            deleteTanda: null,
            group_by: {
                default: 'orchestra',
                type: String
            },
            mode: {
                default: 'list',
                type: String
            },
            used_ids: {
                default: function() { return [] },
                type: Array
            },
            addHook: {
                default: null
            }
        },
        data() {
            return {
                playlist_version: 0,
                playlist: [],
            }
        },
        computed: {
            groups() {
                if (this.group_by === 'orchestra') {
                    return tdj2.computed_orchestras(this.tandas, this.used_ids);
                }
                else if (this.group_by === 'genre') {
                    return tdj2.computed_genres(this.tandas, this.used_ids);
                }
                else if (this.group_by === 'tag') {
                    return this.tandas.reduce((carry, current) => {
                        if (this.used_ids.includes(current.id)) {
                            return carry
                        }
                        let tags = current.tags.length > 0 ? current.tags : [{title: '(без тегов)', id: 0}];
                        for (let tag of tags) {
                            if (carry.hasOwnProperty(tag.title) && Array.isArray(carry[tag.title])) {
                                carry[tag.title].push(current)
                            } else {
                            Object.assign(carry, { [tag.title]: [current] })
                            }
                        }
                        return carry
                    }, {})
                }
            }
        },
        created() {
            this.$root.$on('lpTandaListUpdated', this.updatePlayList);
            this.updatePlayList()
        },
        methods: {
            addTanda(tanda) {

            },
            updatePlayList() {
                this.playlist_version++
                this.playlist = []
                for (let t of this.tandas) {
                    this.playlist.push(tdj2.pls_cortina)
                    for (let te of t.entries) {
                        this.playlist.push([te.instance.file.play_url, te.instance.file.play_title, te.instance.renders, te.id, te.instance.id])
                    }
                }
            },
            checkPlaylist() {
                let pls_id = 'tandas_' + this._uid + '_' + this.playlist_version
                if (tdj2.state.current_playlist_id !== pls_id) {
                    tdj2.state.current_playlist_id = pls_id
                    console.log('switched to playlist ' + pls_id)
                    tdj2.newPlaylist(this.playlist)
                }
            },
            playFile(event){
                this._playFile(event.target.getAttribute('href'))
            },
            _playFile(url) {
                this.checkPlaylist()
                tdj2.playFile(url, this.$root)
            },
        }
    }

</script>
<style lang="less">

    // for screens less than 1400px
    @media screen and (max-width: 1400px) {

        table.tandas {

            tr.tanda {
                td {
                    font-size: 8.5pt;
                }

                td.tags {
                    span.tandatag {
                        border: 0px;
                        font-size: 8.5pt;

                        .octicon {
                            display: none;
                        }
                    }

                    .tandatag-completed {
                        display: none;
                    }

                    .tandatag-approved {
                        display: none;
                    }
                }
            }

        }

    }

    table.tandas {

       .group {
           padding-top: 20px;
       }

        margin-bottom: 30px;
        tr.tanda {
            background-color: #f5f5f5;

            td {
                margin-bottom: 2px;
                padding: 5px;
                padding-left: 8px;
                padding-right: 8px;
            }

            td.years {
                white-space: nowrap;
            }

            td.lp-actions {
                white-space: nowrap;
                a {
                    cursor: pointer;
                }
                .octicon {
                    margin-right: 10px;
                }
            }
        }
    }

</style>