<template>
    <div id="home">

        <add-tracks-dialog v-if="tanda.id" ref="search_dialog" :tanda="tanda" :max_tanda_length="max_tanda_length"
                             :add-hook="add" :remove-hook="remove" :update-hook="recalc_orders" :mark-as-complete-hook="mark_c"  />

        <add-tracks-dialog v-if="tanda.id" ref="wizard_dialog" mode="wizard" :tanda="tanda" :max_tanda_length="max_tanda_length"
                             :add-hook="add" :remove-hook="remove" :update-hook="recalc_orders" :mark-as-complete-hook="mark_c" ></add-tracks-dialog>

        <div v-if="tanda_loaded" class="container-fluid">
            <h2>Танда: {{ tanda.title }}</h2>

            <div v-if="tanda_changed" class="lp-alert">
                <octicon name="alert"></octicon> Вы не сохранили танду после внесения в неё изменений!
            </div>
            <div v-if="!tanda_changed" class="lp-info">
                <octicon name="light-bulb"></octicon> Порядок композиций в танде можно менять перетаскиванием нужного элемента вверх или вниз
            </div>

            <Tanda :tanda="tanda" :update-hook="recalc_orders" :remove-hook="remove"></Tanda>

            <div class="row" style="margin-bottom: 0px;">
                <div class="col-sm-1">Статусы: </div>
                <div class="col-sm-11">
                    <span :class="{'off': !tanda.completed}" @click="tanda.completed = !tanda.completed; tanda_changed = true;" class="clickable tandatag tandatag-completed"><octicon name="check" /> закончена</span>
                    <span :class="{'off': !tanda.approved}" @click="tanda.approved = !tanda.approved; tanda_changed = true;" class="clickable tandatag tandatag-approved"><octicon name="thumbsup" /> одобрена</span>
                    <span :class="{'off': !tanda.use_for_radio}" @click="tanda.use_for_radio = !tanda.use_for_radio; tanda_changed = true;" class="clickable tandatag tandatag-radio"><octicon name="radio-tower" /> radio</span>
                </div>
            </div>
            <div class="row" style="margin-bottom: 30px;">
                <div class="col-sm-1">Теги: </div>
                <div class="col-sm-11">
                    <span v-for="tag in tags" :key="tag.id" :style="'background-color: ' + (tag.color || '#eeeeee') + '; border-color: ' + (tag.border_color || '#d5d5d5') + ';'" :class="{'off': !tags_enabled.includes(tag.id)}" @click="toggle_tag(tag.id)" class="clickable tandatag tandatag-custom"><octicon name="tag" /> {{ tag.title }}</span>
                </div>
            </div>

            <div class="btn-group">

                <button :disabled="!tanda_changed" class="btn btn-small btn-primary" @click="save">
                    <octicon name="cloud-upload"></octicon> сохранить
                </button>

                <button @click="openSearch" class="btn btn-small btn-secondary">
                    <octicon name="diff-added"></octicon> поиск
                </button>

                <button @click="openWizard" :disabled="!tanda.orchestra" class="btn btn-small btn-secondary">
                    <octicon name="diff-added"></octicon> визард
                </button>

                <button @click="download" :disabled="tanda_changed || tanda.entries.length === 0" class="btn btn-small btn-success">
                    <octicon name="cloud-download"></octicon> скачать
                </button>

            </div>

        </div>

    </div>

</template>
<script>

    import axios from 'axios'
    import { tdj2 } from '@/tdj2.js'
    import AddTracksDialog from "@/components/AddTracksDialog";
    import Tanda from "@/components/Tanda";

    export default {

        name: 'tanda',
        props: ['id', 'page'],
        components: {AddTracksDialog, Tanda},

        data() {
            return {
                tanda: {
                    tags: [],
                    entries: [],
                    singers: []
                },
                tanda_loaded: false,
                tanda_changed: false,
                max_tanda_length: 0,
                tags: [],
                tags_loaded: false,
                tags_enabled: []
            }
        },

        created() {
            this.fetchTanda()
            this.fetchTags()
        },

        methods: {
            toggle_tag(tag) {
                if (this.tags_enabled.includes(tag)) {
                    this.tags_enabled.splice(this.tags_enabled.indexOf(tag), 1);
                } else {
                    this.tags_enabled.push(tag)
                }
                this.tanda.tags = this.tags_enabled;
                this.tanda_changed = true;
            },
            fetchTanda() {
                axios.get('/api/tandas/'+this.id+'/')
                .then((result) => {
                    this.tanda = result.data
                    this.tags_enabled = []
                    for (let t of this.tanda.tags) {
                        this.tags_enabled.push(t.id)
                    }
                    this.tanda_loaded = true;
                    this.max_tanda_length = tdj2.getMaxTandaLength(this.tanda.genre)
                    this.$root.$emit('lpTandaLoaded')
                    console.log(result)
                })
                .catch((error) => {
                    console.log(error)
                })
            },
            fetchTags() {
                axios.get('/api/tags/')
                .then((result) => {
                    this.tags = result.data
                    this.tags_loaded = true
                    this.$root.$emit('lpTagsLoaded')
                    console.log('tags loaded')
                })
                .catch((error) => {
                    console.log(error)
                })
            },
            playFile(event){
                tdj2.playFile(event.target.getAttribute('href'), this.$root)
            },
            openSearch() {
                this.$emit('open_search');
            },
            openWizard() {
                this.$emit('open_wizard');
            },
            add(r, i) {
                let r_clone = JSON.parse(JSON.stringify(r))
                let i_clone = JSON.parse(JSON.stringify(i))
                delete r_clone.instances;
                i_clone.recording = r_clone;
                this.tanda.entries.push({
                    id: null,
                    instance: i_clone,
                    order: this.tanda.entries.length+1
                })
                this.tanda_changed = true;
                this.recalc_tanda()
                this.$root.$emit('lpCurrentTandaChanged');
            },
            remove(r, i) {
                console.log('remove(' + i + ')')
                this.tanda.entries = this.tanda.entries.filter(function(value, index, arr){
                    return value.instance.id != i.id;
                });
                this.recalc_orders()
                this.recalc_tanda()
                this.tanda_changed = true;
            },
            mark_c() {
                this.tanda.completed = true;
                this.tanda_changed = true;
            },
            recalc_orders() {
                for(let i in this.tanda.entries) {
                    this.tanda.entries[i].order = parseInt(i) +1
                }
                this.tanda_changed = true;
                this.$root.$emit('lpCurrentTandaChanged');
            },
            save() {
                axios.put('/api/tandas/'+this.id+'/', this.tanda)
                .then((result) => {
                    this.tanda = result.data
                    this.tanda_changed = false;
                    tdj2.fetchProfile()
                    console.log(result)
                })
                .catch((error) => {
                    alert('Ошибка!')
                    console.log(error)
                })
            },
            download() {
                tdj2.download_file('/api/download/tandas/' + this.tanda.id + '/')
            },
            recalc_tanda() {
                let singers = [];
                let orchestras = [];
                let years_from = 0;
                let years_till = 0;
                let titles = [];
                let length = 0;
                for (let e of this.tanda.entries) {
                    let i = e.instance;
                    let r = i.recording;
                    length += i.length;
                    titles.push(r.song.title);
                    if (!tdj2.idInObjArray(r.orchestra.id, orchestras)) {
                        orchestras.push(r.orchestra)
                    }
                    if (r.year && (!years_from || years_from > r.year)) {
                        years_from = r.year
                    }
                    if (r.year && (!years_till || years_till < r.year)) {
                        years_till = r.year
                    }
                    for (let s of r.singers) {
                        if (!tdj2.idInObjArray(s.id, singers)) {
                            singers.push(s)
                        }
                    }
                    this.tanda.orchestra = orchestras.length === 1 ? orchestras[0] : null;
                    this.tanda.singers = singers;
                    this.tanda.years_from = years_from;
                    this.tanda.years_till = years_till;
                    this.tanda.title = titles.join('/')
                    this.tanda.length = length;
                    this.tanda.length_hr = tdj2.formatTime(length);
                }
            }
        }

    }

</script>
<style lang="less">


</style>