<template>
    <div id="home">

        <b-modal ref="edit_dialog" size="lg" no-close-on-backdrop title="Настройки сета" :ok-only="true" @ok="applyChanges">
            <div class="container lp-inline-form">
                <div class="form-group row">
                    <label class="col-sm-6" for="edit_playlist_title">Название</label>
                    <b-form-input  class="col-sm-6" id="edit_playlist_title" type="text" v-model.trim="edit_title"></b-form-input>
                </div>
                <div class="form-group row">
                    <label class="col-sm-6" for="edit_cortina_length">Длина кортин</label>
                    <b-form-input  class="col-sm-6" id="edit_cortina_length" type="number" v-model.number="edit_cortina_length"></b-form-input>
                </div>
            </div>
        </b-modal>

        <add-tanda-dialog :add-hook="add" :djset="record" />

        <div v-if="record_loaded" class="container-fluid">
            <h2>Сет: {{ record.title }} <a @click="edit" class="clickable"><octicon name="pencil"/></a></h2>

            <div v-if="record_changed" class="lp-alert">
                <octicon name="alert"></octicon> Вы не сохранили сет после внесения в него изменений!
            </div>
            <div v-if="!record_changed" class="lp-info">
                <octicon name="light-bulb"></octicon> Порядок танд в сете можно менять перетаскиванием нужного элемента вверх или вниз
            </div>

            <table class="djset">

                <draggable v-model="record.entries" group="entries" @start="drag=true" @end="recalc_orders(); drag=false;">

                    <tbody class="tanda" v-for="(s_entry, s_index) in record.entries" :key="s_entry.id"
                    :class="{'completed': s_entry.tanda.completed, 'approved': s_entry.tanda.approved, 'colored': s_entry.tanda.color}"
                    >
                        <!-- cortina -->
                        <tr class="cortina" v-if="s_index > 0">
                            <td></td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>-- CORTINA --</td>
                            <td>{{ record.cortina_length_hr }}</td>
                        </tr>

                        <!-- tanda entries -->
                        <tr class="tandaentry" v-for="(t_entry, t_index) in s_entry.tanda.entries"
                        :style="'background-color: ' + (s_entry.tanda.color ? s_entry.tanda.color.color : 'auto') + '; border-top: ' + (s_entry.tanda.color ? '1px solid ' + s_entry.tanda.color.border_color  : '0px') + ';'"
                        >
                            <td><span v-if="t_index === 0">{{ s_entry.order }}</span></td>
                            <td class="genre">{{ t_entry.instance.recording.genre.name }}</td>
                            <td class="orchestra">{{ t_entry.instance.recording.orchestra ? t_entry.instance.recording.orchestra.name : 'MIXED' }}</td>
                            <td class="singers"><span v-for="(s, singer_index) in t_entry.instance.recording.singers"><span v-if="singer_index > 0">/</span>{{ s.name }}</span></td>
                            <td class="title"><a :href="t_entry.instance.file.play_url" onclick="return false;" @click="playFile" class="playable">{{ t_entry.instance.recording.song.title }}</a></td>
                            <td class="length">{{ t_entry.instance.length_hr }}</td>
                            <td class="actions">
                                <span v-if="t_index === 0">
                                    <a class="clickable" @click="remove(s_entry.tanda)"><octicon name="trashcan"></octicon></a>
                                </span>
                            </td>
                        </tr>

                        <tr v-if="s_index === record.entries.length-1">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{{ record.length_hr }}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </draggable>

                        <!-- footer -->


            </table>

            <div class="btn-group">

                <button :disabled="!record_changed" class="btn btn-small btn-primary" @click="save">
                    <octicon name="cloud-upload"></octicon> сохранить
                </button>

                <button @click="openAdd" class="btn btn-small btn-secondary">
                    <octicon name="diff-added"></octicon> добавить танду
                </button>

                <button @click="download" :disabled="record_changed || record.entries.length === 0" class="btn btn-small btn-success">
                    <octicon name="cloud-download"></octicon> скачать
                </button>

            </div>

        </div>

    </div>

</template>
<script>

    import axios from 'axios'
    import { tdj2 } from '@/tdj2.js'
    import AddTandaDialog from "@/components/AddTandaDialog";
    import draggable from 'vuedraggable'
    // import Record from "@/components/Record";

    export default {

        name: 'DJSet',
        props: ['id'],
        components: {AddTandaDialog, draggable},

        data() {
            return {
                record: {
                    entries: [],
                    length: 0
                },
                record_loaded: false,
                record_changed: false,
                edit_title: '',
                edit_cortina_length: 0,
                drag: false,
                playlist: [],
                playlist_version: 0
            }
        },

        created() {
            this.fetchRecord()
        },

        methods: {
            edit() {
                this.edit_title = this.record.title;
                this.edit_cortina_length = this.record.cortina_length;
                this.$refs.edit_dialog.show()
            },
            applyChanges (event) {
                if (this.edit_title && this.edit_cortina_length) {
                    this.record.title = this.edit_title
                    this.record.cortina_length = this.edit_cortina_length;
                    this.record.cortina_length_hr = tdj2.formatTime(this.edit_cortina_length);
                    this.record_changed = true;
                    this.$refs.edit_dialog.hide();
                } else {
                    alert('Имя плейлиста не может быть пустым')
                    event.preventDefault()
                }

            },
            openAdd() {
                this.$root.$emit('open_add_tanda')
            },
            fetchRecord() {
                axios.get('/api/djsets/'+this.id+'/')
                .then((result) => {
                    this.record = result.data
                    this.record_loaded = true;
                    this.$root.$emit('lpDJSetLoaded')
                    this.updatePlayList()
                    console.log(result)
                })
                .catch((error) => {
                    console.log(error)
                })
            },
            updatePlayList() {
                this.playlist_version++
                this.playlist = []
                for (let e of this.record.entries) {
                    let t = e.tanda;
                    this.playlist.push(tdj2.pls_cortina)
                    for (let te of t.entries) {
                        this.playlist.push([te.instance.file.play_url, te.instance.file.play_title, te.instance.renders, te.id, te.instance.id])
                    }
                }
            },
            checkPlaylist() {
                let pls_id = 'djset_' + this._uid + '_' + this.playlist_version
                if (tdj2.state.current_playlist_id !== pls_id) {
                    tdj2.state.current_playlist_id = pls_id
                    console.log('switched to playlist ' + pls_id)
                    tdj2.newPlaylist(this.playlist)
                }
            },
            playFile(event){
                this._playFile(event.target.getAttribute('href'))
            },
            _playFile(url) {
                this.checkPlaylist()
                tdj2.playFile(url, this.$root)
            },
            add(tanda) {
                this.record.entries.push({
                    id: null,
                    tanda: tanda,
                    order: this.record.entries.length+1
                })
                this.record_changed = true;
                this.recalc_record()
                this.updatePlayList()
                this.$root.$emit('lpCurrentDJSetChanged');
            },
            remove(tanda) {
                if (!confirm('Удалить танду ' + tanda.title + ' из сета?')) {
                    return;
                }
                this.record.entries = this.record.entries.filter(function(value, index, arr){
                    return value.tanda.id != tanda.id;
                });
                this.recalc_orders()
                this.recalc_record()
                this.record_changed = true;
            },
            recalc_orders() {
                this.updatePlayList()
                for(let i in this.record.entries) {
                    this.record.entries[i].order = parseInt(i) +1
                }
                this.record_changed = true;
                this.$root.$emit('lpCurrentDJSetChanged');
            },
            save() {
                axios.put('/api/djsets/'+this.id+'/', this.record)
                .then((result) => {
                    this.record = result.data
                    this.record_changed = false;
                    tdj2.fetchProfile()
                    console.log(result)
                })
                .catch((error) => {
                    alert('Ошибка!')
                    console.log(error)
                })
            },
            download() {
                tdj2.download_file('/api/download/djsets/' + this.record.id + '/')
            },
            recalc_record() {

                let length = 0;
                for (let e of this.record.entries) {
                    let t = e.tanda;
                    length += t.length;
                }

                length += (this.record.cortina_length * (this.record.entries.length - 1))

                this.record.length = length;
                this.record.length_hr = tdj2.formatTime(length);
            }

        }

    }

</script>
<style lang="less">

    .djset {

        font-size: 9pt;

        margin-bottom: 50px;

        td {
            padding: 3px 10px;
        }

        .tanda {
            transform: none !important;
        }

        .tanda .tandaentry td {
            background-color: #ffd6d6;
            border-top: 1px solid #d6b4b4;
        }

         .tanda.completed .tandaentry td {
            background-color: #f3f3f3;
            border-top: 1px solid #dddddd;
         }

         .tanda.approved .tandaentry td {
            background-color: #c4ffd0;
             border-top: 1px solid #a4d6af;
        }

         .tanda.colored .tandaentry td {
            background-color: transparent !important;
             border-top: 0px !important;
        }

        .tanda .actions {
            padding-top: 0px;
            .octicon{

            }
        }

    }

</style>