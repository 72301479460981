<template>

   <section id="recording_listing" class="container-fluid">

        <template v-if="mode==='search'">
            <h2>Поиск</h2>

            <form @submit="do_search($event)">
               <div class="input-group" style="margin-bottom: 20px; margin-top:20px;">
                    <input :disabled="now_loading" type="text" v-model.trim="new_query" class="form-control" placeholder="Поисковый запрос">
                    <span class="input-group-btn">
                        <button :disabled="now_loading" type="submit" class="btn btn-small btn-primary">Искать</button>
                    </span>
                </div>
            </form>

            <div class="lp-tips">
                <octicon name="info"></octicon> Поиск осуществляется по названиям оркестра, песни и жанра, а также по году и имени певца. Например:
                <ul>
                    <li>"Carlos di Sarli" Tango 194</li>
                    <li>Canaro Poema</li>
                    <li>Biagi Vals</li>
                    <li>Tango "Raul Beron"</li>
                </ul>
            </div>

            <h3>Результаты поиска:</h3>
        </template>

        <template v-if="mode === 'favorites'">
            <h2>Избранное</h2>
        </template>

        <template v-if="['orchestra', 'singer', 'genre', 'song'].includes(mode)">
            <h2>{{ parent.name || parent.title }}</h2>
        </template>

        <div class="lp-error" v-if="error">Ошибка загрузки данных</div>

        <RecordingsList :listing="listing" :groups="groups" v-if="listing_loaded" :green_ids="green_ids" />

        <Pager :go-to-hook="goto_page" :current-listing="listing" v-if="listing.pages > 1"/>

        <template v-if="mode === 'favorites'">
            <div class="btn-group">
                <button @click="download_favorites" :disabled="state.profile.favorite_instances.length === 0" class="btn btn-small btn-success">
                    <octicon name="cloud-download"></octicon> скачать
                </button>
            </div>
        </template>

    </section>

</template>
<script>

    import Pager from "@/components/Pager"
    import RecordingsList from "./RecordingsList";
    import { tdj2 } from "@/tdj2";
    import axios from 'axios'

    export default {

        components: {Pager, RecordingsList},

        props: {
            mode: {
                default:'',
                type: String
            },
            page: {
                default: '1',
                type: String
            },
            id: {
                default: '0',
                type: String
            },
            query: {
                default: '',
                type: String
            },
            parent: {
                default: function() { return {} },
                type: Object
            },
            goToHook: null,
        },

        data() {
            return {
                listing: {pages: 0, results: []},
                listing_loaded: false,
                loader: null,
                now_loading: false,
                error: false,
                groups: {},
                groups_hook: null,
                data_url: '',
                state: tdj2.state,
                green_ids: [],

                // search
                new_query: this.query
            }
        },

        created() {

            // todo: calculate viewname

            this.loader = this.$loading.show({
                canCancel: false,
                color: '#5c6bc0',
            });

            if (this.mode === 'search') {
                this.data_url = '/api/recordings/?search=' + this.query
                this.groups_hook = tdj2.computed_orchestras
            }
            if (this.mode === 'favorites') {
                this.data_url = '/api/recordings/?favorite=1'
                this.groups_hook = tdj2.computed_orchestras
            }
            if (this.mode === 'orchestra') {
                this.data_url = '/api/recordings/?orchestra=' + this.id
                this.groups_hook = tdj2.computed_years
            }
            if (this.mode === 'singer') {
                this.data_url = '/api/recordings/?singer=' + this.id
                this.groups_hook = tdj2.computed_orchestras
            }
            if (this.mode === 'genre') {
                this.data_url = '/api/recordings/?genre=' + this.id
                this.groups_hook = tdj2.computed_orchestras
            }
            if (this.mode === 'song') {
                this.data_url = '/api/recordings/?song=' + this.id
                this.groups_hook = tdj2.computed_orchestras
            }

            this.fetch_listing(this.data_url)

            this.green_ids = tdj2.state.profile.context.instances_used_in_tandas;

        },

        methods: {

            goto_page(page) {
                if (this.mode === 'search') {
                    this.$router.push({name: 'search_query_page', params: {page: page + '', query: this.query}})
                } else {
                    this.$router.push({name: this.mode + '_page', params: {page: page + '', id: this.id}})
                }
            },

            download_favorites() {
                tdj2.download_file('/api/download/favorites/')
            },

            do_search($event) {
                $event.preventDefault();
                if (!this.new_query) {
                    return
                }
                if (this.query === this.new_query) {
                    return
                }
                this.$router.push({ name: 'search_query', params: { query: this.new_query } })
            },

            fetch_listing: function (url) {
                if (this.page) {
                    url = url + '&page=' + this.page
                }
                axios.get(url)
                .then((result) => {
                    this.listing = result.data
                    this.groups = this.groups_hook(this.listing.results)
                    this.listing_loaded = true
                    this.loader.hide()
                })
                .catch((error) => {
                    console.log(error)
                    this.loader.hide()
                })
            },
        }
    }

</script>
<style lang="less">

</style>