import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Orchestras from './views/Orchestras'
import Orchestra from './views/Orchestra'
import Favorites from './views/Favorites'
import Tags from './views/Tags'
import Song from './views/Song'
import Genres from './views/Genres'
import Genre from './views/Genre'
import Singers from './views/Singers'
import Singer from './views/Singer'
import Tandas from './views/Tandas'
import Tanda from './views/Tanda'
import Search from "./views/Search"
import Playlists from './views/Playlists'
import Playlist from './views/Playlist'
import DJSet from './views/DJSet'
import DJSets from './views/DJSets'
import My from "./views/My";

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/my/',
            name: 'my',
            component: My
        },
        {
            path: '/orchestras/',
            name: 'orchestras',
            component: Orchestras
        },
        {
            path: '/orchestras/:id/',
            name: 'orchestra',
            component: Orchestra,
            props:true
        },
        {
            path: '/orchestras/:id/:page/',
            name: 'orchestra_page',
            component: Orchestra,
            props:true
        },
        {
            path: '/favorites/',
            name: 'favorites',
            component: Favorites,
        },
        {
            path: '/favorites/:page/',
            name: 'favorites_page',
            component: Favorites,
            props:true
        },
        {
            path: '/songs/:id/',
            name: 'song',
            component: Song,
            props:true
        },
        {
            path: '/genres/',
            name: 'genres',
            component: Genres,
        },
        {
            path: '/genres/:id/',
            name: 'genre',
            component: Genre,
            props:true
        },
        {
            path: '/genres/:id/:page/',
            name: 'genre_page',
            component: Genre,
            props:true
        },
        {
            path: '/singers/',
            name: 'singers',
            component: Singers,
        },
        {
            path: '/singers/:id/',
            name: 'singer',
            component: Singer,
            props:true
        },
        {
            path: '/singers/:id/:page/',
            name: 'singer_page',
            component: Singer,
            props:true
        },

        {
            path: '/tags/',
            name: 'tags',
            component: Tags,
            props:true
        },
        {
            path: '/tandas/',
            name: 'tandas',
            component: Tandas,
        },
        {
            path: '/tandas/pages/:page/',
            name: 'tandas_page',
            component: Tandas,
            props:true
        },
        {
            path: '/tandas/:id/',
            name: 'tanda',
            component: Tanda,
            props:true
        },
        {
            path: '/sets/',
            name: 'djsets',
            component: DJSets,
        },
        {
            path: '/sets/pages/:page/',
            name: 'djsets_page',
            component: DJSets,
            props:true
        },
        {
            path: '/sets/:id/',
            name: 'djset',
            component: DJSet,
            props:true
        },
        {
            path: '/search/',
            name: 'search',
            component: Search,
        },
        {
            path: '/search/:query/',
            name: 'search_query',
            component: Search,
            props:true
        },
        {
            path: '/search/:query/:page/',
            name: 'search_query_page',
            component: Search,
            props:true
        },
        {
            path: '/playlists/',
            name: 'playlists',
            component: Playlists,
        },
        {
            path: '/playlists/:id/',
            name: 'playlist',
            component: Playlist,
            props:true
        },

    ],
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash }
        } else if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})
