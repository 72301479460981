<template>
    <div id="home">

        <b-modal ref="edit_dialog" size="lg" no-close-on-backdrop title="Настройки плейлиста" :ok-only="true" @ok="applyChanges">
            <div class="container lp-inline-form">
                <div class="form-group row">
                    <label class="col-sm-6" for="edit_playlist_title">Название</label>
                    <b-form-input  class="col-sm-6" id="edit_playlist_title" type="text" v-model.trim="edit_title"></b-form-input>
                </div>
            </div>
        </b-modal>

        <add-tracks-dialog ref="search_dialog" mode="playlist" :tanda="playlist"
                           :add-hook="add" :remove-hook="remove" :update-hook="recalc_orders"  />

        <div v-if="playlist_loaded" class="container-fluid">
            <h2>Плейлист: {{ playlist.title }} <a @click="edit" class="clickable"><octicon name="pencil"/></a></h2>

            <div v-if="playlist_changed" class="lp-alert">
                <octicon name="alert"></octicon> Вы не сохранили плейлист после внесения в него изменений!
            </div>
            <div v-if="!playlist_changed" class="lp-info">
                <octicon name="light-bulb"></octicon> Порядок композиций в плейлисте можно менять перетаскиванием нужного элемента вверх или вниз
            </div>

            <tanda :tanda="playlist" :update-hook="recalc_orders" :remove-hook="remove"></tanda>

            <div class="btn-group">

                <button :disabled="!playlist_changed" class="btn btn-small btn-primary" @click="save">
                    <octicon name="cloud-upload"></octicon> сохранить
                </button>

                <button @click="openSearch" class="btn btn-small btn-secondary">
                    <octicon name="diff-added"></octicon> поиск
                </button>

                <button @click="download" :disabled="playlist_changed || playlist.entries.length === 0" class="btn btn-small btn-success">
                    <octicon name="cloud-download"></octicon> скачать
                </button>

            </div>

        </div>


    </div>

</template>
<script>

    import axios from 'axios'
    import { tdj2 } from '@/tdj2.js'
    import AddTracksDialog from "@/components/AddTracksDialog";
    import Tanda from '@/components/Tanda.vue';

    export default {

        name: 'playlist',
        props: ['id', 'page'],
        components: {AddTracksDialog, Tanda},

        data() {
            return {
                playlist: {},
                playlist_loaded: false,
                playlist_changed: false,
                max_playlist_length: 0,
                edit_title: ''
            }
        },

        created() {
            this.fetchPlaylist()
        },

        methods: {
            edit() {
                this.edit_title = this.playlist.title
                this.$refs.edit_dialog.show()
            },
            applyChanges (event) {
                if (this.edit_title) {
                    this.playlist.title = this.edit_title
                    this.playlist_changed = true;
                    this.$refs.edit_dialog.hide();
                } else {
                    alert('Имя плейлиста не может быть пустым')
                    event.preventDefault()
                }

            },
            fetchPlaylist() {
                axios.get('/api/playlists/'+this.id+'/')
                .then((result) => {
                    this.playlist = result.data
                    this.playlist_loaded = true;
                    // tdj2.newplaylist(this.playlist)
                    console.log(result)
                })
                .catch((error) => {
                    console.log(error)
                })
            },
            playFile(event){
                tdj2.playFile(event.target.getAttribute('href'), this.$root)
            },
            openSearch() {
                this.$emit('open_search');
            },
            add(r, i) {
                let r_clone = JSON.parse(JSON.stringify(r))
                let i_clone = JSON.parse(JSON.stringify(i))
                delete r_clone.instances;
                i_clone.recording = r_clone;
                this.playlist.entries.push({
                    id: null,
                    instance: i_clone,
                    order: this.playlist.entries.length+1
                })
                this.playlist_changed = true;
                this.$root.$emit('lpCurrentplaylistChanged');
            },
            remove(r, i) {
                console.log('remove(' + i + ')')
                this.playlist.entries = this.playlist.entries.filter(function(value, index, arr){
                    return value.instance.id !== i.id;
                });
                this.recalc_orders()
                this.playlist_changed = true;
            },
            mark_c() {
                this.playlist.completed = true;
            },
            recalc_orders() {
                for(let i in this.playlist.entries) {
                    this.playlist.entries[i].order = parseInt(i) +1
                }
                this.playlist_changed = true;
                this.$root.$emit('lpCurrentplaylistChanged');
            },
            download() {
                tdj2.download_file('/api/download/playlists/' + this.playlist.id + '/')
            },
            save() {
                axios.put('/api/playlists/'+this.id+'/', this.playlist)
                .then((result) => {
                    this.playlist = result.data
                    this.playlist_changed = false;
                    tdj2.fetchProfile()
                    console.log(result)
                })
                .catch((error) => {
                    alert('Ошибка!')
                    console.log(error)
                })
            }
        }

    }

</script>
<style lang="less">


</style>