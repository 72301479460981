<template>
    <RecordingsPage mode="genre" :id="id" :page="page" :parent="currentGenre" v-if="currentGenre.id" />
</template>

<script>

    import {tdj2} from '@/tdj2.js'
    import RecordingsPage from "@/components/RecordingsPage";

    export default {
        components: {RecordingsPage},
        props: ['id', 'page'],
        name: 'genre',

        created () {

            this.$root.$on('lpGenresLoaded', () => {
                this.processGenre()
            })

            if (this.state.genres_loaded) {
                this.processGenre()
            } else {
                tdj2.fetchGenres(this.$root)
            }

        },

        methods: {

            processGenre: function () {
                for (let o of this.state.genres) {
                    if (String(o.id) == String(this.id)) {
                        this.currentGenre = o
                        console.log('genre processed')
                    }
                }
            },
        },

        data () {
            return {
                state: tdj2.state,
                currentGenre: {name: '', id: null}
            }
        },

    }

</script>

<style lang="less">

</style>
