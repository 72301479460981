<template>
    <div>

        <h4>Статистика проекта</h4>
        <div class="tdj2-stats">
            <ul>
                <li>Пользователей: <b>{{ stats.user_count }}</b></li>
                <li>Собрано танд: <b>{{ stats.tanda_count }}</b>
                  <em> (без учета ваших: <b>{{ stats.tanda_count - stats.tanda_count_your }}</b>)</em></li>
                <li>Одобренных танд: <b>{{ stats.tanda_count_approved }}</b>
                  <em> (без учета ваших: <b>{{ stats.tanda_count_approved - stats.tanda_count_approved_your }}</b>)</em></li>
            </ul>
        </div>

        <h4>Топ оркестров по популярности</h4>
        <div class="tdj2-stats">
            <ol class="">
              <li v-for="item in stats.orchestras"><b>{{ item.orchestra_name }}</b> <em>({{ item.count }})</em></li>
            </ol>
          <em class="legend">Рейтинг оркестров по количеству танд (приведено в скобках).</em>
        </div>

        <h4>Топ оркестров по удельной популярности</h4>
        <div class="tdj2-stats">
            <ol>
              <li v-for="item in stats.orchestras_sp"><b>{{ item.orchestra_name }}</b> <em>({{ item.weight }})</em></li>
            </ol>
            <em class="legend">Рейтинг оркестров по отношению количества танд к количеству записей у оркестра (приведено в скобках).</em>
        </div>

        <h4>Топ пользователей</h4>
        <div class="tdj2-stats">
            <ol>
                <li v-for="item in stats.top_users">Пользователь #{{ item.user_id }} ({{ item.count }})
                  <em v-if="item.user_id === state.profile.id"> (вы)</em>
                </li>
            </ol>
            <em class="legend">Анонимный рейтинг пользователей по количеству танд (приведено в скобках).</em>
        </div>

        <h4>Количество танд по годам</h4>
      <div class="years-container">
        <years-chart v-if="stats.user_count > 0 && state.profile_loaded" :chartdata="stats.years_data" :options="{maintainAspectRatio: false,    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }}"></years-chart>
      </div>

    </div>
</template>
<script>
  import {tdj2} from '@/tdj2';
    import axios from "axios";
    import YearsChart from "@/components/YearsChart";


    export default {

      components: {
        YearsChart
      },

      data() {
            return {
                state: tdj2.state,
                stats: {
                  user_count: 0
                }
            }
        },

        created() {
            this.fetchData();
        },

        methods: {
            fetchData() {
                axios.get('/api/recordings/get_stats/')
                .then((result) => {
                    this.stats = result.data
                }).catch(error => {
                    console.log(error)
                })
            },
        }

    }

</script>
<style lang="less">

    .tdj2-stats {
        ul {
            li {
              margin-left: 20px;
            }
          margin-bottom: 4px;
        }

        ol {
            li {
                margin-left: 27px;
            }
          margin-bottom: 4px;
        }

      em {
        font-style: normal;
        color: #999999;
            &.legend {
                //margin-top: -5px;
            }
      }

      .years-container {
        height: 300px;
      }

      margin-bottom: 30px;


    }

</style>