<template>
    <section>
        <recordings-page mode="favorites" :page="page" />
    </section>
</template>

<script>

    import {tdj2} from '@/tdj2.js'
    import RecordingsPage from "@/components/RecordingsPage";

    export default {
        components: {RecordingsPage},
        props: ['page'],
        name: 'favorites',
        data() {
            return {
                state: tdj2.state
            }
        }

}
</script>

<style lang="less">

</style>
