<template>
    <RecordingsPage mode="song" :id="id" :page="page" :parent="song" v-if="song.id" />
</template>

<script>

    import axios from 'axios'
    import {tdj2} from '@/tdj2.js'
    import RecordingsPage from "@/components/RecordingsPage";

    export default {
        components: {RecordingsPage},
        props: ['id', 'page'],
        name: 'singer',

        created () {

            this.processSong()

        },

        methods: {

            processSong: function () {
               console.log('fetch song')
                axios.get('/api/songs/' + this.id + '/')
                .then((result) => {
                    this.song = result.data
                }).catch(error => {
                    console.log(error)
                })
            }
        },

        data () {
            return {
                state: tdj2.state,
                song: {title: '', id: null},
            }
        }

    }

</script>

<style lang="less">

</style>
