<template>
    <RecordingsPage mode="orchestra" :parent="currentOrchestra" :page="page" :id="id" v-if="currentOrchestra.id" />
</template>

<script>
    import 'vue-octicon/icons'

    import {tdj2} from '@/tdj2.js'
    import RecordingsPage from "@/components/RecordingsPage";

    export default {
        components: {RecordingsPage},
        props: ['id', 'page'],
        name: 'orchestra',

        created () {

            this.$root.$on('lpOrchestrasLoaded', () => {
                this.processOrchestra()
            })

            if (this.state.orchestras_loaded) {
                this.processOrchestra()
            } else {
                tdj2.fetchOrchestras(this.$root)
            }

        },

        methods: {

            processOrchestra: function() {
                for (let o of this.state.orchestras) {
                    if (String(o.id) == String(this.id)) {
                        this.currentOrchestra = o
                        console.log('orchestra processed')
                    }
                }
            },

        },

        data () {
            return {
                state: tdj2.state,
                currentOrchestra: {name: '', id: null},
            }
        }

    }

</script>

<style lang="less">

</style>
