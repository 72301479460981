<template>
    <div ref="contentContainer">

        <instance-info/>
        <instance-comments/>

        <p>Записей: <b>{{ listing.count }}</b>, страница <b>{{ listing.page }}</b> из <b>{{ listing.pages}}</b></p>

        <table class=" r_listing">
            <template v-for="(group, g_index) in Object.keys(groups)" class="group">
                <tr class="group"><td :colspan="table_cols"><h5>{{ group }}</h5></td></tr>
                <template v-for="(r, innerIndex) in groups[group]" :class="recording">

                    <!-- single-instance recording -->

                    <template v-if="r.instances.length == 1">
                        <tr class="recording" :id="'i_'+r.instances[0].id" v-contextmenu:contextmenu :r="r" :i="r.instances[0]">
                            <td class="title">
                                <span class="lp-first">
                                    <octicon class="instance-icon default" name="file-code" scale="1"></octicon>
                                    <octicon class="instance-icon now-playing" name="unmute" scale="1"></octicon>
                                </span>
                                <span class="title"><a :href="r.instances[0].file.play_url" onclick="return false;" @click="playFile" class="clickable">{{ r.song.title }}</a></span><span class="subtitle" v-if="r.subtitle"> ({{ r.subtitle }})</span>
                                <span v-if="r.instances[0].orphaned" class="badge badge-danger">недоступен</span>
                                <span :class="'badge badge-lp-' + r.instances[0].file.source.name ">{{ r.instances[0].file.source.name }}</span>
                                <span class="badge badge-info" v-for="tag in r.instances[0].tags">{{ tag }}</span>
                            </td>
                            <td class="singers"><span v-for="(s, index) in r.singers"><span v-if="index > 0">, </span>{{ s.name }}</span></td>
                            <td class="genre">{{ r.genre.name }}</td>
                            <td class="year">{{ r.year }}</td>
                            <td class="pre-gap" title="Секунд тишины в начале композиции">{{ r.instances[0].pre_gap }}</td>
                            <td class="length" title="Длина композиции">{{ r.instances[0].length_hr }}</td>
                            <td class="post-gap" title="Секунд тишины в конце композиции">{{ r.instances[0].post_gap }}</td>
                            <td class="bpm" title="Темп, ударов в минуту">
                                {{ r.instances[0].bpm }}
                                <template v-if="r.instances[0].bpm !== '--'">
                                    <span class="bpm_comment"> BPM</span>
                                </template>
                            </td>
                            <td class="format">{{ r.instances[0].format }}<span v-if="r.instances[0].codec"> ({{ r.instances[0].codec }})</span></td>
                            <td class="lp-actions" :class="viewmode">
                                <section v-if="viewmode === 'view'">
                                    <a title="Информация о файле" @click="showInstanceInfo(r.instances[0], r)"><octicon name="info" class="info"></octicon><!--<span class="badge badge-info">3</span>--></a>
                                    <a title="Избранное" @click="toggleFavorite(r.instances[0].id)"><octicon name="star" class="favorite" :id="'fav_' + r.instances[0].id"></octicon></a>
                                    <a title="Комментарии" @click="showInstanceComments(r.instances[0])">
                                        <template v-if="r.instances[0].comments > 0">
                                            <octicon name="comment-discussion" class="comments-yes"></octicon><span class="badge badge-primary">{{ r.instances[0].comments }}</span>
                                        </template>
                                        <template v-if="r.instances[0].comments === 0">
                                            <octicon name="comment" class="comments-no"></octicon>
                                        </template>
                                    </a>
                                </section>
                                <section v-if="viewmode === 'add'">
                                    <a title="Избранное" @click="toggleFavorite(r.instances[0].id)"><octicon name="star" class="favorite" :id="'fav_' + r.instances[0].id"></octicon></a>
                                    <a v-if="!remove_ids.includes(r.instances[0].id)" @click="addHook(r, r.instances[0])" class="add add-primary clickable">добавить</a>
                                    <a v-if="remove_ids.includes(r.instances[0].id)" @click="removeHook(r, r.instances[0])" class="remove remove-primary clickable">удалить</a>
                                </section>
                            </td>
                            <td class="ctx" v-if="viewmode === 'view'">
                                <a title="Контекстное меню" v-contextmenu:contextmenu_click :r="r" :i="r.instances[0]"><octicon name="three-bars" class="menu"></octicon></a>
                            </td>
                        </tr>
                    </template>

                    <!-- multiple-instance recording -->

                    <template v-if="r.instances.length > 1">

                        <tr class="recording" :id="'r_'+r.id" @click="toggleCollapsation(r.id)" style="cursor:pointer;">
                            <td class="title">
                                <span class="lp-first">
                                    <octicon name="chevron-down" v-show="!(expanded_ids.includes(r.id) || !state.profile.recordings_collapsed)" scale="1"></octicon>
                                    <octicon name="chevron-up" v-show="expanded_ids.includes(r.id) || !state.profile.recordings_collapsed" scale="1"></octicon>
                                </span>
                                <span class="title">{{ r.song.title }}</span><span class="subtitle" v-if="r.subtitle"> ({{ r.subtitle }})</span> ({{ r.instances.length }})
                            </td>
                            <td class="singers"><span v-for="(s, index) in r.singers">{{ s.name }}<span v-if="index+1 < r.singers.length">, </span></span></td>
                            <td class="genre">{{ r.genre.name }}</td>
                            <td class="year">{{ r.year }}</td>
                            <td class="pre-gap"></td>
                            <td class="length"></td>
                            <td class="post-gap"></td>
                            <td class="bpm"></td>
                            <td class="format">({{ r.instances.length }})</td>
                            <td class="lp-actions"></td>
                            <td class="ctx" v-if="viewmode === 'view'"></td>
                        </tr>

                        <template class="instances">
                            <tr class="instance" :id="'i_'+i.id" :tdj-parent="r.id" v-show="expanded_ids.includes(r.id) || !state.profile.recordings_collapsed"
                                 v-contextmenu:contextmenu  v-for="i in r.instances" :r="r" :i="i">
                                <td class="title">
                                    <span class="lp-first">
                                        <octicon class="instance-icon default" name="file-code" scale="1"></octicon>
                                        <octicon class="instance-icon now-playing" name="unmute" scale="1"></octicon>
                                    </span>
                                    <span class="title">
                                        <a :href="i.file.play_url" onclick="return false;" @click="playFile" class="clickable">
                                            {{ r.song.title }}</a></span><span class="subtitle" v-if="r.subtitle"> ({{ r.subtitle }})</span>
                                    <span :class="'badge badge-lp-' + i.file.source.name ">{{ i.file.source.name }}</span>
                                    <span class="badge badge-info" v-for="tag in i.tags">{{ tag }}</span>
                                </td>
                            <td class="lp-inherited singers"><span v-for="(s, index) in r.singers">{{ s.name }}<span v-if="index+1 < r.singers.length">, </span></span></td>
                            <td class="lp-inherited genre">{{ r.genre.name }}</td>
                            <td class="lp-inherited year">{{ r.year }}</td>
                            <td class="pre-gap" title="Секунд тишины в начале композиции">{{ i.pre_gap }}</td>
                            <td class="length" title="Длина композиции">{{ i.length_hr }}</td>
                            <td class="post-gap" title="Секунд тишины в конце композиции">{{ i.post_gap }}</td>
                            <td class="bpm" title="Темп, ударов в минуту">
                                {{ i.bpm }}
                                <template v-if="i.bpm !== '--'">
                                    <span class="bpm_comment"> BPM</span>
                                </template>
                            </td>
                            <td class="format">{{ i.format }}<span v-if="i.codec"> ({{ i.codec }})</span></td>
                            <td class="lp-actions" :class="viewmode">
                                <section v-if="viewmode === 'view'">
                                    <a title="Информация о файле" @click="showInstanceInfo(i, r)"><octicon name="info" class="info"></octicon><!--<span class="badge badge-info">3</span>--></a>
                                    <a title="Избранное" @click="toggleFavorite(i.id)"><octicon name="star" class="favorite" :id="'fav_' + i.id"></octicon></a>
                                    <a title="Комментарии" @click="showInstanceComments(i)">
                                        <template v-if="i.comments > 0">
                                            <octicon name="comment-discussion" class="comments-yes"></octicon><span class="badge badge-primary">{{ i.comments }}</span>
                                        </template>
                                        <template v-if="i.comments === 0">
                                            <octicon name="comment" class="comments-no"></octicon>
                                        </template>
                                    </a>
                                </section>
                                <section v-if="viewmode === 'add'">
                                    <a @click="toggleFavorite(i.id)" title="Избранное"><octicon name="star" class="favorite" :id="'fav_' + i.id"></octicon></a>
                                    <a v-if="!remove_ids.includes(i.id)" @click="addHook(r, i)" class="add add-primary clickable">добавить</a>
                                    <a v-if="remove_ids.includes(i.id)" @click="removeHook(r, i)" class="remove remove-primary clickable">удалить</a>
                                </section>
                            </td>
                                <td class="ctx" v-if="viewmode === 'view'">
                                    <a title="Контекстное меню" v-contextmenu:contextmenu_click :r="r" :i="i"><octicon name="three-bars" class="menu"></octicon></a>
                                </td>
                            </tr>
                        </template>

                    </template>

                    <tr class="recording-end"><td :colspan="table_cols"></td></tr>
                </template>
                <tr class="group-end"><td :colspan="table_cols"></td></tr>
            </template>
        </table>

        <!-- context menu -->

        <v-contextmenu ref="contextmenu">
            <template v-if="!menu_current_i">
                <v-contextmenu-item disabled>No current item!</v-contextmenu-item>
            </template>
            <template v-if="menu_current_i">
                <v-contextmenu-item @click="_playFile(menu_current_i.file.play_url)">Воспроизвести</v-contextmenu-item>
                <v-contextmenu-item @click="menu_download_file(menu_current_i.id)">Скачать</v-contextmenu-item>
                <v-contextmenu-item @click="toggleFavorite(menu_current_i.id)" v-if="!state.profile.favorite_instances.includes(menu_current_i.id)">Добавить в избранное</v-contextmenu-item>
                <v-contextmenu-item @click="toggleFavorite(menu_current_i.id)" v-if="state.profile.favorite_instances.includes(menu_current_i.id)">Удалить из избранного</v-contextmenu-item>
                <v-contextmenu-item disabled>Информация о файле</v-contextmenu-item>
                <v-contextmenu-item divider></v-contextmenu-item>
                <v-contextmenu-submenu title="Перейти к">
                    <v-contextmenu-item @click="$router.push('/songs/' + menu_current_r.song.id + '/')" >Все записи песни {{ menu_current_r.song.title }}</v-contextmenu-item>
                    <v-contextmenu-item @click="$router.push('/orchestras/' + menu_current_r.orchestra.id + '/')" v-if="menu_current_r.orchestra">Все записи оркестра {{ menu_current_r.orchestra.name }}</v-contextmenu-item>
                    <v-contextmenu-item @click="$router.push('/singers/' + s.id + '/')" v-if="s.name !== 'Instrumental'" v-for="s in menu_current_r.singers" :key="s.id">Все записи певца {{ s.name }}</v-contextmenu-item>
                </v-contextmenu-submenu>
                <v-contextmenu-item divider></v-contextmenu-item>
                <v-contextmenu-item @click="menu_start_tanda">Начать танду</v-contextmenu-item>
                <v-contextmenu-submenu title="Добавить в танду">
                    <template v-if="state.profile.context.unfinished_tandas.length < 1">
                        <v-contextmenu-item disabled>(нет)</v-contextmenu-item>
                    </template>
                    <template v-if="state.profile.context.unfinished_tandas.length > 0">
                        <v-contextmenu-item @click="menu_add_to_tanda(t.id)" v-for="t in state.profile.context.unfinished_tandas" v-bind:key="t.id">
                            {{ t.title }} ({{ t.orchestra }}/{{ t.genre }}, {{ t.count }})
                        </v-contextmenu-item>
                    </template>
                </v-contextmenu-submenu>
                <v-contextmenu-item divider></v-contextmenu-item>
                <v-contextmenu-item @click="menu_start_playlist">Начать новый плейлист</v-contextmenu-item>
                <v-contextmenu-submenu title="Добавить в плейлист">
                    <template v-if="state.profile.context.playlists.length < 1">
                        <v-contextmenu-item disabled>(нет)</v-contextmenu-item>
                    </template>
                    <template v-if="state.profile.context.playlists.length > 0">
                        <v-contextmenu-item @click="menu_add_to_playlist(p.id)" v-for="p in state.profile.context.playlists" v-bind:key="p.id">
                            {{ p.title }} ({{ p.count }})
                        </v-contextmenu-item>
                    </template>
                </v-contextmenu-submenu>
            </template>
        </v-contextmenu>

        <v-contextmenu ref="contextmenu_click" event-type="click">
            <template v-if="!menu_current_i">
                <v-contextmenu-item disabled>No current item!</v-contextmenu-item>
            </template>
            <template v-if="menu_current_i">
                <v-contextmenu-item @click="_playFile(menu_current_i.file.play_url)">Воспроизвести</v-contextmenu-item>
                <v-contextmenu-item @click="menu_download_file(menu_current_i.id)">Скачать</v-contextmenu-item>
                <v-contextmenu-item @click="toggleFavorite(menu_current_i.id)" v-if="!state.profile.favorite_instances.includes(menu_current_i.id)">Добавить в избранное</v-contextmenu-item>
                <v-contextmenu-item @click="toggleFavorite(menu_current_i.id)" v-if="state.profile.favorite_instances.includes(menu_current_i.id)">Удалить из избранного</v-contextmenu-item>
                <v-contextmenu-item disabled>Информация о файле</v-contextmenu-item>
                <v-contextmenu-item divider></v-contextmenu-item>
                <v-contextmenu-submenu title="Перейти к">
                    <v-contextmenu-item @click="$router.push('/songs/' + menu_current_r.song.id + '/')" >Все записи песни {{ menu_current_r.song.title }}</v-contextmenu-item>
                    <v-contextmenu-item @click="$router.push('/orchestras/' + menu_current_r.orchestra.id + '/')" v-if="menu_current_r.orchestra">Все записи оркестра {{ menu_current_r.orchestra.name }}</v-contextmenu-item>
                    <v-contextmenu-item @click="$router.push('/singers/' + s.id + '/')" v-if="s.name !== 'Instrumental'" v-for="s in menu_current_r.singers" :key="s.id">Все записи певца {{ s.name }}</v-contextmenu-item>
                </v-contextmenu-submenu>
                <v-contextmenu-item divider></v-contextmenu-item>
                <v-contextmenu-item @click="menu_start_tanda">Начать танду</v-contextmenu-item>
                <v-contextmenu-submenu title="Добавить в танду">
                    <template v-if="state.profile.context.unfinished_tandas.length < 1">
                        <v-contextmenu-item disabled>(нет)</v-contextmenu-item>
                    </template>
                    <template v-if="state.profile.context.unfinished_tandas.length > 0">
                        <v-contextmenu-item @click="menu_add_to_tanda(t.id)" v-for="t in state.profile.context.unfinished_tandas" v-bind:key="t.id">
                            {{ t.title }} ({{ t.orchestra }}/{{ t.genre }}, {{ t.count }})
                        </v-contextmenu-item>
                    </template>
                </v-contextmenu-submenu>
                <v-contextmenu-item divider></v-contextmenu-item>
                <v-contextmenu-item @click="menu_start_playlist">Начать новый плейлист</v-contextmenu-item>
                <v-contextmenu-submenu title="Добавить в плейлист">
                    <template v-if="state.profile.context.playlists.length < 1">
                        <v-contextmenu-item disabled>(нет)</v-contextmenu-item>
                    </template>
                    <template v-if="state.profile.context.playlists.length > 0">
                        <v-contextmenu-item @click="menu_add_to_playlist(p.id)" v-for="p in state.profile.context.playlists" v-bind:key="p.id">
                            {{ p.title }} ({{ p.count }})
                        </v-contextmenu-item>
                    </template>
                </v-contextmenu-submenu>
            </template>
        </v-contextmenu>

    </div>

</template>
<script>

    import InstanceInfo from "./InstanceInfo";
    import InstanceComments from "./InstanceComments";
    import { tdj2 } from '@/tdj2.js'

    export default {
        name: 'RecordingsList',
        components: {InstanceInfo, InstanceComments},

        props: {
            viewmode: {
                default: 'view',
                type: String
            },
            listing: {},
            groups: {},

            green_ids: {
                default: function () { return [] },
                type: Array
            },
            red_ids: {
                default: function () { return [] },
                type: Array
            },

            // for "add" mode
            remove_ids: {
                default: function () { return [] },
                type: Array
            },
            addHook: null,
            removeHook: null,

        },

        data() {
            return {
                table_cols: this.viewmode === 'add' ? 10 : 11,
                state: tdj2.state,
                expanded_ids: [],
                playlist_version: 0,

                // context menu
                menu_current_r: null,
                menu_current_i: null
            }
        },

        mounted() {
            console.log('mounted()')
            this.initTableClasses()
            this.$root.$emit('lpUpdateHeight');
            this.$refs.contextmenu.$on('contextmenu', this.menu_handler)
            this.$refs.contextmenu_click.$on('contextmenu', this.menu_handler)
            this.updatePlayList()
        },

        created() {

            console.log('created()')

            this.$root.$on('lpColorClassAdded', (i, color) => {
                this.setClassRec(i, color, true)
            })

            this.$root.$on('lpColorClassRemoved', (i, color) => {
                this.setClassRec(i, color, false)
            })

            this.$root.$on('lpProfileLoaded', () => {
                this.initTableClasses()
            })

            this.$root.$on('player-play-instance', (item) => {
                this.setClass(item, 'now-playing', true)
            })
            this.$root.$on('player-pause-instance', (item) => {
                this.setClass(item, 'now-playing', false)
            })
            this.$root.$on('lpSetInstanceCommentCount', this.setCommentCount)

        },

        methods: {

            setCommentCount(id, count) {
                for (let r of this.listing.results) {
                    for (let i of r.instances) {
                        if (i.id === id) {
                            i.comments = count;
                        }
                    }
                }
            },

            showInstanceInfo(i, r) {
                this.$root.$emit('lpShowInstanceInfo', i, r)
            },
            showInstanceComments(i) {
                this.$root.$emit('lpShowInstanceComments', i)
            },

            // menu-related

            menu_handler(reference) {
                this.menu_current_r = reference.data.attrs.r
                this.menu_current_i = reference.data.attrs.i
            },

            show_menu ($event) {
                // const position = {
                //     top: $event.clientY,
                //     left: $event.clientX,
                // }
                // this.$refs.contextmenu.show(position)
                this.$refs.contextmenu.show()
                console.log(this.$refs.contextmenu)
            },

            menu_download_file(id) {
                const url = '/api/download/instances/' + id + '/';
                tdj2.download_file(url)
            },

            menu_start_tanda() {
                if (this.menu_current_i) {
                    tdj2.startTanda(this.menu_current_i.id, this.$root)
                }
            },

            menu_add_to_tanda(tanda_id) {
                if (this.menu_current_i) {
                    tdj2.addInstanceToTanda(tanda_id, this.menu_current_i.id, this.menu_current_r.id, this.$root)
                }
            },

            menu_start_playlist() {
                if (this.menu_current_i) {
                    tdj2.startPlaylist(this.menu_current_i.id, this.$root)
                }
            },

            menu_add_to_playlist(pls_id) {
                if (this.menu_current_i) {
                    tdj2.addInstanceToPlaylist(pls_id, this.menu_current_i.id, this.menu_current_r.id, this.$root)
                }
            },

            // table-classes related

            initTableClasses() {

                for (let i of tdj2.state.profile.favorite_instances) {
                    this.setClassRec(i, 'favorite', true)
                }

                for (let i of this.green_ids) {
                    this.setClassRec(i, 'green', true)
                }

                this._init_red()

                if (tdj2.state.is_playing && tdj2.state.player_current_track != null && this.state.playlist.length > tdj2.state.player_current_track) {
                    this.setClass(this.state.playlist[this.state.player_current_track][3], 'now-playing', true)
                }
            },

            _init_red(){
                for (let i of this.red_ids) {
                    this.setClassRec(i, 'red', true)
                }
            },

            setClassRec(id, klass, value) {
                // console.log('setClassRec(' + id + ', ' + klass + ', ' + value + ')')
                let obj = document.getElementById('i_'+id);
                if (obj) {
                    this.setNodeClass(obj, klass, value)
                    if (obj.getAttribute('tdj-parent')) {
                        let par = document.getElementById('r_' + obj.getAttribute('tdj-parent'))
                        if (par) {
                            var still_marked = false
                            for (let lite of document.querySelectorAll('[tdj-parent="'+obj.getAttribute('tdj-parent')+'"]')) {
                                if (lite.id != 'i_'+id) {
                                    if (!value) {
                                        if (lite.classList.contains(klass)) {
                                            still_marked = true
                                        }
                                    }

                                    if (!still_marked) {
                                        this.setNodeClass(lite, klass + '-lite', value)
                                    }
                                }
                            }
                            if (!still_marked) {
                                this.setNodeClass(par, klass, value)
                            }
                            if (!value && !still_marked) {
                                this.setNodeClass(obj, klass+'-lite', value)
                            }

                        }
                    }
                }
            },

            setClass(id, klass, value) {
                // console.log('setClass(' +id + ', ' + klass + ', ' + value + ')')
                let obj = document.getElementById('i_'+id);
                if (obj) {
                    this.setNodeClass(obj, klass, value)
                }
            },

            setNodeClass(node, klass, value) {
                if (value) {
                    node.classList.add(klass)
                } else {
                    node.classList.remove(klass)
                }
            },

            // player-related

            checkPlaylist() {
                let pls_id = 'rlisting_' + this._uid + '_' + this.playlist_version
                if (tdj2.state.current_playlist_id !== pls_id) {
                    tdj2.state.current_playlist_id = pls_id
                    console.log('switched to playlist ' + pls_id)
                    tdj2.newPlaylist(this.playlist)
                }
            },

            updatePlayList() {
                this.playlist_version++
                this.playlist = []
                for (let r of this.listing.results) {
                    for (let i of r.instances) {
                        this.playlist.push([i.file.play_url, i.file.play_title, i.renders, i.id, r.id])
                    }
                }
            },

            playFile(event) {
                this._playFile(event.target.getAttribute('href'))
            },

            _playFile(url) {
                if (this.state.player_current_track != null) {
                    this.setClass(this.state.playlist[this.state.player_current_track][3], 'now-playing', false)
                }
                this.checkPlaylist()
                tdj2.playFile(url, this.$root)
            },

            toggleCollapsation(id) {
                if (this.expanded_ids.includes(id)) {
                    this.expanded_ids.splice(this.expanded_ids.indexOf(id), 1);
                } else {
                    this.expanded_ids.push(id)
                }
            },

            toggleFavorite(id) {
                let val = tdj2.toggleFavoriteInstance(id, this.$root)
                this.setClassRec(id, 'favorite', this.state.profile.favorite_instances.includes(id))
                return val
            }
        }

    }

</script>
<style lang="less">

    #recording_listing {

        h2 {
            margin-bottom: 0px !important;
        }

    }

    .r_listing {

        margin-top: 25px;
        width: 100%;

        /* common things */

        .group-end {
            height: 25px;
        }

        .recording-end {
            height: 4px;
        }

        /* default record states */

        tr.recording, tr.instance {
            td {
                font-size: 10pt;
                padding-top: 3px;
                padding-bottom: 3px;
                padding-left: 7px;
            }
            td.genre {
                width: 80px;
            }
            td.year {
                width: 50px;
            }
            td.length {
                width: 47px;
                text-align: center;
                padding-left: 0px;
            }
            td.pre-gap {
                width: 40px;
                padding-right: 0px;
                text-align: right;
                opacity: 0.5;
            }
            td.post-gap {
                width: 50px;
                padding-left: 0px;
                opacity: 0.5;
            }
            td.bpm {
                width: 70px;
                .bpm_comment {
                    opacity: 0.5;
                }
            }
            td.format {
                width: 90px;
            }
            td.lp-actions {
                padding-right: 7px;
                .info {
                    color: #AAAAAA;
                }
                .comments-no {
                    color: #AAAAAA;
                }

                .octicon {
                    cursor: pointer;
                }

                a.clickable {
                    display: inline-block;
                    margin-left: 6px;
                }
            }

            td.lp-actions.view {
                width: 120px;
            }

            td.lp-actions.add {
                width: 136px;
            }

            .octicon.favorite {
                color: black;
                opacity: 0.2;
            }

        }

        /* special record states */

        tr.recording.favorite, tr.instance.favorite {
            .octicon.favorite {
                color: #5c6bc0;
                opacity: 1;
            }
        }
        tr.recording.favorite {
            background-color: #f2f1d3;
        }
        tr.instance.favorite-lite {
            background-color: #f6f5e7;
        }
        tr.instance.favorite {
            background-color: #f6f6d7;
        }
        tr.recording.red {
            background-color: #f6bcbb;
        }
        tr.instance.red-lite {
            background-color: #f6d5d2;
        }
        tr.instance.red {
            background-color: #f6cac6;
        }
        tr.recording.green {
            background-color: #81f694;
        }
        tr.instance.green-lite {
            background-color: #adf6b4;
        }
        tr.instance.green {
            background-color: #9df6a7;
        }

        tr.recording.now-playing, tr.instance.now-playing {
            .octicon {
                color: white;
            }
            .octicon.favorite {
                color: white;
                opacity: 0.3;
            }

        }

        tr.recording.favorite.now-playing, tr.instance.favorite.now-playing {
            .octicon.favorite {
                opacity: 1;
            }
        }

        // rest

        .title .lp-first {
            width: 20px;
            display: inline-block;
        }

        h5 {
            padding-bottom: 10px;
            margin-bottom: 13px;
            border-bottom: 1px solid #d0d5f1;
        }

        tr.instance {
            background-color: #f5f5f5;
        }

        tr.instance:hover {
            background-color: #e5e5ff;
        }

        tr.recording {
            border-top: 1px solid #e5e5e5;
            background-color: #f0f0f0;
            .lp-multiple {
                color: #cccccc;
            }
            span.title {
                font-weight: bold;
            }
            .col {
                padding-top: 3px;
                padding-bottom: 3px;
            }
            .subtitle {
                color: #888888;
            }
            .tags {
                color: #cccccc;
            }
            .singers {
                white-space: nowrap;
            }
        }

        .instance {
            font-size: 10pt;
            .lp-inherited {
                color: #cccccc;
                /*padding-left: 0px;*/
            }
            .lp-first {
            width: 20px;
            display: inline-block;
                margin-left: 20px;
        }
            div.title {
                /*padding-left: 30px;*/
            }
            .col {
                padding-top: 3px;
                padding-bottom: 3px;
            }
            .subtitle {
                color: #888888;
            }
            .tags {
                color: #cccccc;
            }
            .singers {
                white-space: nowrap;
            }
        }

    }


    .instance .length,
    .recording .length {
        font-weight: bold;
    }

    .recording {
        &.now-playing {
            background-color: #5c6bc0 !important;
        }

    }

    .instance {
        &.now-playing {
            background-color: #5c6bc0 !important;
        }

    }

    .instance td.title:before, .recording td.title:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    .instance .badge, .recording .badge {
        margin-left: 5px;
        font-size: 8.5pt;
        padding-top: 3px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 3px;
        display: inline-block;
        vertical-align: top;
        margin-top: 1px;
    }

    .instance .octicon, .recording .octicon {
        display: inline-block;
        vertical-align: top;
        margin-top: 1px;
    }

    .octicon.menu {
        color: #333333;
    }

    .lp-actions .octicon {
        margin-left: 8px;
    }

    .ctx {
        width: 40px;
        a {
            cursor: pointer;
        }
    }

    .lp-actions {

        .add.add-primary {
            background-color: #007bff;
            color: white;
            display: inline-block;
            padding: 1px 7px;
            border-radius: 4pt;
            margin-left: 16px !important;
        }
        .add.add-primary:hover {
            background-color: #0069d9;
            color: white;
        }

        .remove.remove-primary {
            background-color: #ff4645;
            color: white;
            display: inline-block;
            padding: 1px 7px;
            border-radius: 4pt;
            margin-left: 16px !important;
            width: 73px;
            text-align: center;
        }
        .remove.remove-primary:hover {
            background-color: #df3d3c;
            color: white;
        }

    }

    .instance-icon.default {
        display: inline-block;
    }

    .instance-icon.now-playing {
        display: none;
    }

    .now-playing {
        color: white;
        .instance-icon.default {
            display: none;
        }

        .instance-icon.now-playing {
            display: inline-block;
            color: white;
        }
        a {
            color: white !important;
        }

    }

</style>