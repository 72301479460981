<script>
    import { Bar } from 'vue-chartjs'
    export default {
        extends: Bar,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },

        mounted () {
            var data = this.prepareData(this.chartdata);
            console.log(data);
            this.renderChart(data, this.options);
        },

        methods: {
            prepareData(data) {

              var labels = [];
              var counts = [];

              for (let item of data) {
                labels.push(item.year);
                counts.push(item.count);
              }

                return {

     labels: labels,
      datasets: [
        {
          label: 'Популярность песен этого года',
          backgroundColor: '#5c6bc0',
          data: counts
        }
      ]
                }
            }
        }
    }
</script>