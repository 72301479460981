<template>

    <div v-if="recording_loaded" class="instance_info">

        <transition name="modal" >
            <div class="modal-mask" v-show="showModal">
                <div class="modal-wrapper">
                    <div class="modal-container">

                        <h4>Данные о записи</h4>

                        <div class="block">
                            <div class="row">
                                <div class="label col col-sm col-sm-4">Песня</div>
                                <div class="data col col-sm col-sm-8">
                                    <span class="value">
                                        <router-link :to="'/songs/' + recording.song.id + '/'">{{ recording.song.title }}</router-link>
                                        <span v-if="recording.subtitle"> ({{ recording.subtitle }})</span>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="label col col-sm col-sm-4">Оркестр</div>
                                <div class="data col col-sm col-sm-8">
                                    <span class="value">
                                        <router-link :to="'/orchestras/' + recording.orchestra.id + '/'">{{ recording.orchestra.name }}</router-link>
                                    </span>
                                </div>
                            </div>
                            <div class="row" v-if="recording.singers.length === 1">
                                <div class="label col col-sm col-sm-4">Певец</div>
                                <div class="data col col-sm col-sm-8">
                                    <span class="value">
                                        <router-link :to="'/singers/' + recording.singers[0].id + '/'">{{ recording.singers[0].name }}</router-link>
                                    </span>
                                </div>
                            </div>
                            <div class="row" v-if="recording.singers.length > 1">
                                <div class="label col col-sm col-sm-4">Певцы</div>
                                <div class="data col col-sm col-sm-8">
                                    <span class="value">
                                        <span v-for="(s, s_index) in recording.singers">
                                            <span v-if="s_index > 0">, </span>
                                            <router-link :to="'/singers/' + s.id + '/'">{{ s.name }}</router-link>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="label col col-sm col-sm-4">Год</div>
                                <div class="data col col-sm col-sm-8">
                                    <span class="value">{{ recording.year }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="label col col-sm col-sm-4">Жанр</div>
                                <div class="data col col-sm col-sm-8">
                                    <span class="value">
                                        <router-link :to="'/genres/' + recording.genre.id + '/'">{{ recording.genre.name }}</router-link>
                                    </span>
                                </div>
                            </div>
                            <div class="row" v-if="recording.original_genre.id">
                                <div class="label col col-sm col-sm-4">Оригинальный жанр</div>
                                <div class="data col col-sm col-sm-8">
                                    <span class="value">{{ recording.original_genre.name }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="block">
                            <h4>Данные о файле</h4>

                            <div class="row">
                                <div class="label col col-sm col-sm-4">Теги</div>
                                <div class="data col col-sm col-sm-8">
                                    <span v-if="instance.tags.length === 0">(нет)</span>
                                    <span v-for="(tag, t_index) in instance.tags" class="tag"><span v-if="t_index > 0">, </span>{{ tag }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="label col col-sm col-sm-4">Длина</div>
                                <div class="data col col-sm col-sm-8">
                                    <span class="value">{{ instance.length_hr }}</span>
                                    <span v-if="instance.pre_gap !== '--' && instance.post_gap !== '--'"> (<span class="value">{{ instance.pre_gap }}</span> сек. тишины в начале и <span class="value">{{ instance.post_gap }}</span> сек. в конце)</span>
                                </div>
                            </div>
                            <div class="row" v-if="instance.bpm !== '--'">
                                <div class="label col col-sm col-sm-4">Темп</div>
                                <div class="data col col-sm col-sm-8">
                                    <span class="value">{{ instance.bpm }} BPM</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="label col col-sm col-sm-4">Формат</div>
                                <div class="data col col-sm col-sm-8">
                                    <span class="value">{{ instance.format }}</span>
                                    <span v-if="instance.codec && instance.codec !== instance.format"> (Кодек: <span class="value">{{ instance.codec }})</span></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="label col col-sm col-sm-4">Источник</div>
                                <div class="data col col-sm col-sm-8">
                                    <span class="value">{{ instance.file.source.name }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="block" v-if="recording.instances.length > 1">
                            <h4>Все версии данной записи</h4>
                            <div @click="instance = i" class="row variant" :class="{'current': i.id === instance.id}" v-for="i in recording.instances">
                                <td class="col col-sm col-sm-2">{{ i.file.source.name }}</td>
                                <td class="col col-sm col-sm-3">
                                    <span v-for="(tag, t_index) in i.tags" class="tag"><span v-if="t_index > 0">, </span>{{ tag }}</span>
                                </td>
                                <td class="col col-sm col-sm-2">{{ i.format }}
                                    <span v-if="i.codec && i.codec !== i.format">({{ i.codec }})</span>
                                </td>
                                <td class="col col-sm col-sm-1">{{ i.pre_gap }}</td>
                                <td class="col col-sm col-sm-1">{{ i.length_hr }}</td>
                                <td class="col col-sm col-sm-1">{{ i.post_gap }}</td>
                            </div>
                        </div>

                        <div style="padding-top: 20px;">
                            <button class="btn btn-small btn-primary" @click="hide">Закрыть</button>
                        </div>

                    </div>
                </div>
            </div>
        </transition>
    </div>

</template>
<script>

    import axios from 'axios'

    export default {

        name: 'InstanceInfo',
        data() {
            return {
                instance: {},
                recording: {},
                recording_loaded: false,
                showModal: false
            }
        },
        created() {
            this.$root.$on('lpShowInstanceInfo', this.showInstance)
        },
        methods: {
            hide() {
                this.showModal = false;
            },
            showInstance(instance, recording) {
                this.instance = instance;
                if (recording.instances && recording.instances.length > 1) {
                    this.recording = recording;
                    this.recording_loaded = true;
                } else {
                    this.fetchRecording(recording.song.id, recording.id)
                }
                this.showModal = true;
            },
            fetchRecording(song_id, recording_id) {
                axios.get('/api/recordings/?song=' + song_id )
                .then((result) => {
                    for (let r of result.data.results) {
                        if (r.id === recording_id) {
                            this.recording = r;
                            this.recording_loaded = true;
                        }
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
        }
    }

</script>
<style lang="less">

    .instance_info {

        .row.variant {
            background-color: #eeeeee;
            border-top: 1px solid #dddddd;
            margin-bottom: 2px;
            cursor: pointer;
            margin-left: 0px;
            margin-right: 0px;
        }

        .row.variant.current {
            background-color: #5c6bc0;
            color: white;
        }

        .block {
            margin-bottom: 20px;
            font-size: 9pt;
        }

        h4 {
            margin-bottom: 20px;
        }

        .row {
            .col {
                padding-bottom: 0px;
                padding-top: 2px;
            }
        }

        .label {
            color: #777777;
        }
        .data {
            .tag {
                font-weight: bold;
            }
        }
        .value {
            font-weight: bold;
            display: inline-block;
            /*margin-right: 5px;*/
        }
    }

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: top;
}

.modal-container {
  width: 600px;
  margin: 30px auto;
  padding: 30px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  /*font-family: Helvetica, Arial, sans-serif;*/
    max-height: calc(100vh - 60px);
    overflow-y: scroll;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>