<template>
   <table class="tandaentries">
       <draggable v-model="tanda.entries" group="entries" @start="drag=true" @end="update">
            <tr class="tandaentry" v-for="(entry, e_index) in tanda.entries">
                <td>{{ entry.order }}</td>
                <td class="orchestra">{{ entry.instance.recording.orchestra.name }}</td>
                <td class="singers">
                    <span v-for="(singer, s_index) in entry.instance.recording.singers"><span v-if="s_index > 0">, </span>{{ singer.name }}</span>
                </td>
                <td class="title">
                    <a :href="entry.instance.file.play_url" onclick="return false;" @click="playFile(entry.instance.file.play_url)" class="playable">{{ entry.instance.recording.song.title }}</a>
                    <span v-if="entry.instance.recording.subtitle" class="subtitle"> ({{ entry.instance.recording.subtitle }})</span>
                    <span :class="'badge badge-lp-' + entry.instance.file.source.name ">{{ entry.instance.file.source.name }}</span>
                    <span class="badge badge-info" v-for="tag in entry.instance.tags">{{ tag }}</span>
                </td>
                <td class="year">
                    {{ entry.instance.recording.year }}
                </td>
                <td class="genre">
                    {{ entry.instance.recording.genre.name }}
                </td>
                <td>{{ entry.instance.length_hr }}</td>
                <td>{{ entry.instance.bpm }}<span v-if="entry.instance.bpm !== '--'" class="bpm_comment"> BPM</span></td>
                <td v-if="removeHook"><a class="clickable red"  @click="remove(entry.instance.recording, entry.instance)"><octicon name="trashcan"></octicon></a></td>
            </tr>
           <tr>
               <td colspan="7"></td>
               <td class="total">{{ tanda.length_hr }}</td>
               <td></td>
           </tr>
       </draggable>
    </table>
</template>
<script>

    import { tdj2} from "@/tdj2";
    import draggable from 'vuedraggable'

    export default {
        name: "Tanda",

        components: {
            draggable,
        },

        props: {
            tanda: {
                type: Object,
                default: function () {
                    return {
                        entries: [],
                        tags: [],
                        singers: []
                    }
                }
            },
            updateHook: null,
            removeHook: null
        },

        data() {
            return {
                state: tdj2.state,
                playlist: [],
                playlist_version: 0,
                drag: false
            }
        },

        created() {
            this.updatePlayList()
            this.$root.$on('lpCurrentTandaChanged', this.updatePlayList)
        },

        methods: {
            updatePlayList() {
                this.playlist_version++
                this.playlist = []
                console.log(this.tanda)
                console.log(this.tanda.entries)
                for (let te of this.tanda.entries) {
                    this.playlist.push([te.instance.file.play_url, te.instance.file.play_title, te.instance.renders, te.id, te.instance.id])
                }
                console.log('new tanda playlist')
                console.log(this.playlist)
                console.log(this.playlist_version)
            },
            playFile(url) {
                this.checkPlaylist()
                tdj2.playFile(url, this.$root)
            },
            checkPlaylist() {
                let pls_id = 'tanda_' + this._uid + '_' + this.playlist_version
                if (tdj2.state.current_playlist_id !== pls_id) {
                    tdj2.state.current_playlist_id = pls_id
                    console.log('switched to playlist ' + pls_id)
                    tdj2.newPlaylist(this.playlist)
                }
            },
            update() {
                if (this.updateHook) {
                    this.updateHook()
                }
            },
            remove(r, i) {
                if (this.removeHook) {
                    if (confirm('Удалить композицию из танды?')) {
                        this.removeHook(r, i)
                    }
                }
            }

        }
    }

</script>
<style lang="less">


    .tandaentries {
        margin-bottom: 30px;

        td.total {
            padding-left: 8px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 8px;
        }

        .tandaentry {
            td {
                padding-left: 8px;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 8px;
                border-top: 1px solid #eeeeee;
                background-color: #f5f5f5;
            }

            .bpm_comment {
                opacity: 0.5;
            }

            .badge {
                margin-left: 5px;
                font-size: 8.5pt;
                padding-top: 3px;
                padding-left: 5px;
                padding-right: 5px;
                padding-bottom: 3px;
                display: inline-block;
                vertical-align: top;
                margin-top: 3px;
            }

             .octicon {
                display: inline-block;
                vertical-align: top;
                margin-top: 3px;
            }

        }
    }

</style>