<template>

    <div class="player_controls">

        <svg style="display: none;">
            <symbol id="icon-play" viewBox="0 0 1024 1024">
                <path d="M511.2269 64.4608c-246.1655 0-446.4425 200.278-446.4425 446.4425 0 246.1655 200.277 446.4435 446.4425 446.4435s446.4425-200.278 446.4425-446.4435C957.6694 264.7388 757.3914 64.4608 511.2269 64.4608zM511.2269 901.5409c-215.4015 0-390.6376-175.2361-390.6376-390.6376 0-215.4015 175.2361-390.6365 390.6376-390.6365 215.4015 0 390.6365 175.2351 390.6365 390.6365C901.8634 726.3048 726.6273 901.5409 511.2269 901.5409zM705.3455 459.649l-238.807-129.622c-11.3347-6.186-23.5151-9.4556-35.2328-9.4556-34.5508 0-59.5927 26.5134-59.5927 63.0272v254.61248c0 36.5117 25.0419 62.9975 59.5927 63.0262 11.7166 0 23.8971-3.2696 35.1519-9.3993l240.087-129.7326c21.718-11.8262 34.1985-30.2735 34.1985-50.5743C740.7432 490.8216 727.852 471.9114 705.3455 459.649zM679.9227 513.0568 439.8356 642.7884c-4.1974 2.3183-7.168 2.6429-8.5299 2.6429-1.5247 0-3.7878 0-3.7878-7.2212V383.59859199999994c0-7.2212 2.263-7.2212 3.7878-7.2212 1.3629 0 4.3325 0.3543 8.5555 2.6429l238.8357 129.6507c1.7172 0.9533 3.0515 1.8524 4.0878 2.6429C682.0219 511.8587 681.0665 512.4577 679.9227 513.0568z" ></path>
            </symbol>
            <symbol id="icon-play-pause" viewBox="0 0 1024 1024">
                <path d="M960 512c0-247.36-200.576-448-448-448C264.64 64 64 264.64 64 512c0 247.424 200.64 448 448 448C759.424 960 960 759.424 960 512zM128 511.936c0-212.032 171.904-384 384-384s384 171.968 384 384-171.904 384-384 384S128 723.968 128 511.936zM406.784 678.208c-22.656 0.064-41.088-19.072-41.088-42.56L365.696 383.04c0-23.68 18.432-42.688 41.152-42.688C429.568 340.224 448 359.488 448 382.976l0 252.544C448 659.136 429.568 678.272 406.784 678.208zM617.088 678.208C594.368 678.272 576 659.136 576 635.648L576 383.04c0-23.68 18.432-42.688 41.152-42.688 22.656-0.128 41.152 19.136 41.152 42.624l0 252.544C658.24 659.136 639.808 678.272 617.088 678.208z"  ></path>
            </symbol>
            <symbol id="icon-play-prev" viewBox="0 0 1024 1024">
                <path d="M128 102.4V928c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32V102.4c0-17.673-14.327-32-32-32-17.673 0-32 14.327-32 32z m646.028 41.395l-404.352 331.09c-27.383 22.42-27.435 58.886 0 81.35l404.352 331.09c27.382 22.42 49.676 12.003 49.676-23.366V167.16c0-35.646-22.24-45.83-49.676-23.365z m-40.546-49.518c69.12-56.597 154.222-16.897 154.222 72.883v696.8c0 89.481-85.14 129.447-154.222 72.883L329.13 605.753c-58.7-48.064-58.662-132.353 0-180.386l404.352-331.09z" fill="" ></path>
            </symbol>
            <symbol id="icon-play-next" viewBox="0 0 1024 1024">
                <path d="M889.6 921.6V96c0-17.673-14.327-32-32-32-17.673 0-32 14.327-32 32v825.6c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32z m-646.028-41.395l404.352-331.09c27.383-22.42 27.435-58.886 0-81.35l-404.352-331.09c-27.382-22.42-49.676-12.003-49.676 23.366V856.84c0 35.646 22.24 45.83 49.676 23.365z m40.546 49.518c-69.12 56.597-154.222 16.897-154.222-72.883v-696.8c0-89.481 85.14-129.447 154.222-72.883l404.352 331.09c58.7 48.064 58.662 132.353 0 180.386l-404.352 331.09z" fill="" ></path>
        </symbol>
        </svg>

        <template v-if="state.player_current_track == null">
            <div class="controls disabled">

                <div class="icon prev">
                    <svg aria-hidden="true"><use xlink:href="#icon-play-prev" /></svg>
                </div>

                <div class="icon play">
                    <svg aria-hidden="true"><use xlink:href="#icon-play" /></svg>
                </div>

                <div class="icon next">
                    <svg aria-hidden="true"><use xlink:href="#icon-play-next" /></svg>
                </div>

            </div>
        </template>

        <template v-if="state.player_current_track != null">
            <div class="controls">

                <div class="icon prev clickable" @click="prev">
                    <svg aria-hidden="true"><use xlink:href="#icon-play-prev" /></svg>
                </div>

                <div class="icon play clickable" v-if="!state.is_playing" @click="play">
                    <svg aria-hidden="true"><use xlink:href="#icon-play" /></svg>
                </div>

                <div class="icon play clickable" v-if="state.is_playing" @click="pause">
                    <svg aria-hidden="true"><use xlink:href="#icon-play-pause" /></svg>
                </div>

                <div class="icon next clickable" @click="next">
                    <svg aria-hidden="true"><use xlink:href="#icon-play-next" /></svg>
                </div>

            </div>
        </template>

    </div>

</template>
<script>

    import { tdj2} from "@/tdj2";

    export default {
        name: 'PlayerControls',

        data() {
            return {
                state: tdj2.state
            }
        },
        methods: {
            play() {
                this.$root.$emit('player-do-play')
            },
            pause() {
                this.$root.$emit('player-do-pause')
            },
            prev() {
                this.$root.$emit('player-do-prev')
            },
            next() {
                this.$root.$emit('player-do-next')
            }
        }
    }

</script>
<style lang="less">

    .player_controls {
        width: 100%;
        height: 100%;
        z-index: 255;

        .controls {
            height: 100%;

            &.disabled {
                opacity: 0.5;
            }

            .icon {
                display: inline-block;

                svg{
                    height: 100%;
                    width: 100%;
                    fill: currentColor;
                }

            }

            .icon.play {
                height: 100%;
                width: calc(100% / 3);
            }

            .icon.prev, .icon.next {
                height: 50%;
                width: calc(50% / 3);
                margin-top: 8%;
            }

            .icon.prev {
                margin-right: calc(100% / 6);
            }

            .icon.next {
                margin-left: calc(100% / 6);
            }

        }



    }

</style>