import Vue from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'

import BootstrapVue from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import Vuetify from "vuetify";
// import "vuetify/dist/vuetify.css";

import Octicon from 'vue-octicon/components/Octicon.vue'
// import 'vue-octicon/icons/play'

// import '@/assets/sass/app.sass'

import contentmenu from 'v-contextmenu'
import 'v-contextmenu/dist/index.css'

import Notifications from '@/modules/vue-notification'
import velocity      from 'velocity-animate'
var VueCookie = require('vue-cookie');

import { tdj2 } from '@/tdj2.js'

Vue.component('octicon', Octicon)

Vue.use(BootstrapVue)
Vue.use(Vuetify);
Vue.use(Octicon);
Vue.use(Loading);
Vue.use(Notifications, { velocity })
Vue.use(VueCookie);

Vue.use(contentmenu)

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
    created () {
        tdj2.fetchProfile(this.$root)
    }
}).$mount('#app');
