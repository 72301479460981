<template>

    <div class="player_title" v-if="state.player_current_track != null">
        {{ state.playlist[state.player_current_track][1] }}
    </div>

</template>
<script>

    import {tdj2} from "@/tdj2";

    export default {
        name: 'PlayerTitle',
        data() {
            return {
                state: tdj2.state
            }
        }
    }

</script>
<style>

</style>