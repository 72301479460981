<template>
    <div id="home">
        <div v-if="records_loaded" class="container-fluid">
            <h2>Сеты</h2>

            <table class="records">
                <tr v-for="record in records" class="record">
<!--                    <td>#{{ record.id }}</td>-->
                    <td>
                        <router-link :to="'/sets/' + record.id + '/'">{{ record.title }}</router-link>
                    </td>
                    <td>({{ record.length_hr }}) [{{ record.entries.length }}]</td>
                    <td class="lp-actions">
                        <a title="Скачать сет" @click="download_djset(record.id)"><octicon name="cloud-download"/></a>
                        <a title="Удалить сет" @click="deleteRecord(record)"><octicon name="trashcan"></octicon></a>
                    </td>
                </tr>
            </table>

            <div class="btn-group">

                <button class="btn btn-small btn-primary" @click="createRecord"><octicon name="diff-added"></octicon> создать сет</button>

                <button :disabled="records.length < 1" class="btn btn-small btn-success" @click="download_djsets">
                    <octicon name="cloud-download"></octicon> скачать все сеты
                </button>

            </div>

        </div>
    </div>

</template>
<script>

    import { tdj2 } from '@/tdj2.js'
    import axios from 'axios'

    export default {

        name: 'DJSets',
        props: ['page'],

        data() {
            return {
                state: tdj2.state,
                records: [],
                records_loaded: false,
            }
        },

        created() {
            this.fetchRecords()
        },

        methods: {
            download_djsets() {
                tdj2.download_file('/api/download/djsets/')
            },
            download_djset(id) {
                tdj2.download_file('/api/download/djsets/' + id + '/')
            },
            fetchRecords() {
                axios.get('/api/djsets/')
                .then((result) => {
                    this.records = result.data
                    this.records_loaded = true;
                })
                .catch((error) => {
                    console.log(error)
                })
            },
            createRecord() {
                axios.post('/api/djsets/', {
                    'title': 'Новый сет'
                })
                .then((result) => {
                    this.fetchRecords()
                    tdj2.fetchProfile()
                })
                .catch((error) => {
                    console.log(error)
                    alert('Ошибка')
                })
            },
            deleteRecord(record) {
                if (confirm('Удалить сет ' + record.title + '?')) {
                    axios.delete('/api/djsets/' + record.id + '/')
                    .then((result) => {
                        this.fetchRecords()
                        tdj2.fetchProfile()
                    })
                    .catch((error) => {
                        console.log(error)
                        alert('Ошибка')
                    })
                }
            },
        }
    }

</script>
<style lang="less">

    table.records {
        margin-bottom: 30px;
        tr.record {
            background-color: #f5f5f5;
            td {
                margin-bottom: 2px;
                border-top: 1px solid #eeeeee;
                padding: 5px;
                padding-left: 8px;
                padding-right: 8px;
            }

            td.lp-actions {
                a {
                    cursor: pointer;
                }
            }
        }
    }

</style>